import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo,
  useCallback,
} from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MaterialTable, { MTableBodyRow, MTableCell } from "@material-table/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";
import { toast } from "react-toastify";
import Alert from "@material-ui/lab/Alert";

import NothingToShow from "../../nothingToShow/NothingToShow";
import { useLocale } from "../../../core/hooks/useLocale";
import { addRowNumberToTable, tableLocales } from "../../../util/UtilTable";
import CustomDialog from "../../dialogs/fullScreenDialog/CustomDialog";
import {
  getFormSchemaDefinitionByCompleteIdControl,
  makeValuesToSetValues,
  makeValuesToSetValuesMultiple,
  resolveFieldAgainstShadowStatus,
} from "../../../util/UtilForm";
import {
  BREAKPOINT_LG,
  FIELD_SEPARATOR,
  LINE_SEPARATOR,
  TOAST_CONTAINER_LAYOUT,
} from "../../../util/Constants";
import { KEY_ENTER } from "../../../util/UtilKeys";
import { AuthContext } from "../../../core/providers/AuthContext";
import CustomBodyToast from "../../toast/CustomBodyToast";
import * as handlersAPI from "./api";
import { useRequestHeaders } from "../../../core/hooks/useRequestHeaders";
import useWindowDimensions from "../../../core/hooks/useWindowDimensions";
import { SEL_CLI_PASTE_AREA } from "../clientServices/services";
import { useExternalFormWithUkey } from "../../../core/hooks/useExternalFormWithUkey";
import { useProcessHistoryDialog } from "../../../core/hooks/useProcessHistoryDialog";
import HeaderFieldsToPropagate from "./HeaderFieldsToPropagate";
import HandlerContextMenuPasteAreaHelpDialog from "./HandlerContextMenuPasteAreaHelpDialog";

const baseBtnTransformHover = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  borderRadius: "5px",
  border: "none",
  height: "30px",
  marginRight: "10px",
  "&:hover": {
    transform: "scale(1.1)",
  },
};
const useStyles = makeStyles((theme) => ({
  serviceRow: {
    "&:hover": {
      color: theme.palette.form.selectionItemText,
      borderRadius: 10,
      backgroundColor: theme.palette.form.selectionItemBorder,
    },
  },
  buttonTransformHover: {
    ...baseBtnTransformHover,
    backgroundColor: theme.palette.content.mainColor,
    color: theme.palette.header.textColor,
  },
  buttonTransformHoverCancel: {
    ...baseBtnTransformHover,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  contentContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  headerDiv: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
    height: "3%",
    position: "sticky",
    top: 0,
  },
  headerDivMultiple: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    gap: "5px",
    position: "sticky",
    top: 0,
  },
  dataDiv: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
  },
  footerDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "4%",
    position: "sticky",
    bottom: 0,
  },
  footerDivEnd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  footerDivInternal: {
    display: "flex",
  },
  pasteAreaControl: {
    marginTop: 30,
  },
  iconExternal: {
    color: "yellowgreen",
    fontSize: "0.95rem",
    padding: 1,
    width: "auto",
    height: "auto",
  },
  confirmOverrideDialogActions: {
    justifyContent: "space-evenly",
  },
}));

const sharedIconStyles = {
  padding: 1,
  width: "auto",
  height: "auto",
};

export const GLOBAL_GRID_MODE_REPLACE = "REPLACE";
export const GLOBAL_GRID_MODE_APPEND = "APPEND";
export const GLOBAL_GRID_MODE_CONFIRM = "CONFIRM";

const ENABLED_BUTTON_TO_ADD_PASTED = false;

function mustThrowErrorByRequiredFields({
  requiredPropagatedFields,
  propagatedFields,
  hasFieldsToAddInHeaderToPropagate,
}) {
  if (
    _.isNil(requiredPropagatedFields) ||
    !_.isArray(requiredPropagatedFields) ||
    _.isEmpty(requiredPropagatedFields) ||
    _.isNil(hasFieldsToAddInHeaderToPropagate) ||
    hasFieldsToAddInHeaderToPropagate === false
  ) {
    return false;
  }

  return _.some(requiredPropagatedFields, (requiredField) => {
    const foundValue = _.get(propagatedFields, requiredField);

    return (
      _.isNil(foundValue) || (_.isString(foundValue) && _.isEmpty(foundValue))
    );
  });
}

function parseTextAreaStringToArray(
  textAreaPastedString,
  dataResultDef,
  removeDuplicatedLines
) {
  const linesBefore = _.filter(
    textAreaPastedString.split(LINE_SEPARATOR),
    function (o) {
      return !_.isNil(o) && !_.isEmpty(o);
    }
  );

  const lines =
    removeDuplicatedLines === true ? _.uniqBy(linesBefore) : linesBefore;

  const result = [];

  for (let i = 0; i < lines.length; i++) {
    const lineData = lines[i].split(FIELD_SEPARATOR);
    const obj = {};

    for (let j = 0; j < dataResultDef.length; j++) {
      if (!_.isNil(dataResultDef[j])) {
        const prop = dataResultDef[j].field;
        const value = _.trim(_.toString(lineData[j]));
        obj[prop] = value;
      }
    }

    result.push(obj);
  }

  return result;
}

function flattenArrayToString(arrayBase, fieldSeparator, lineSeparator) {
  if (
    !_.isNil(arrayBase) &&
    !_.isEmpty(arrayBase) &&
    !_.isNil(fieldSeparator) &&
    !_.isEmpty(fieldSeparator) &&
    !_.isNil(lineSeparator) &&
    !_.isEmpty(lineSeparator)
  ) {
    const array = _.clone(arrayBase);
    const groupedValues = {};

    // Group values by line
    for (let i = 0; i < _.size(array); i++) {
      const item = array[i];

      if (!groupedValues[item.line]) {
        groupedValues[item.line] = [];
      }
      const itemValueToAdd = _.toString(item.value).replace(fieldSeparator, "");
      groupedValues[item.line].push(itemValueToAdd);
    }

    // Flatten the grouped values into a string
    const flattenedString = [];
    for (const line in groupedValues) {
      flattenedString.push(groupedValues[line].join(";"));
    }

    // Combine flattened values with '&&' separator
    return flattenedString.join(lineSeparator);
  } else {
    return "";
  }
}

function expandArray(originalArray, valueMaxLine, startLine) {
  const newArray = [];
  for (let i = startLine; i <= valueMaxLine; i++) {
    originalArray.forEach((item) => {
      const newItem = { ...item };
      newItem.line = i;
      newArray.push(newItem);
    });
  }
  return newArray;
}

export default function ContextMenu(props) {
  const {
    uniqueID,
    services,
    setValue,
    menuPosition,
    closeMenu,
    setComeFromSelection,
    line,
    formInstanceId,
    auto,
    interactiveBtnDef,
    isCalledFromGlobalFilterInGrid,
    alreadyInfoInGrid,
    preLoadedPasteInfo,
    shadowStatus,
    disabledSelectionServicesDynamic,
    formSchema,
    formData,
  } = props;

  const theme = useTheme();
  const tableRef = useRef();
  const textAreaRef = useRef();
  const chargeDataBtnRef = useRef();
  const classes = useStyles();
  const [locale] = useLocale();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [columns, setColumns] = useState();
  const [rows, setRows] = useState();
  const [fetching, isFetching] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedMultipleRows, setSelectedMultipleRows] = useState(null);
  const [selectedService, setSelectedService] = useState();
  const [searchingText, setSarchingText] = useState(null);
  const [serviceErrorMessageNoData, setServiceErrorMessageNoData] =
    useState(null);
  let tableLocale = tableLocales.find((x) => x.key === locale)?.properties;
  const [openPasteArea, setOpenPasteArea] = useState(false);
  const [textPastedValue, setTextPastedValue] = useState(
    preLoadedPasteInfo?.resultString || ""
  );
  const [debounceAllSelected, setDebounceAllSelected] = useState(false);
  const [effectExecuted, setEffectExecuted] = useState(false);
  const [propagatedFields, setPropagatedFields] = useState({});
  const [valuesToSetFromPropagatedFields, setValuesToSetFromPropagatedFields] =
    useState([]);
  const [chooseClicked, setChooseClicked] = useState(false);

  const [showPasteAreaHelp, setShowPasteAreaHelp] = useState(false);

  const [ProcessHistoryDialog, handleOpenProcessHistoryDialog] =
    useProcessHistoryDialog();

  const { auth } = useContext(AuthContext);

  const wd = useWindowDimensions();

  const REQUEST_HEADERS = useRequestHeaders();

  const [ExternalForm, handleOpenFormWithUkey] = useExternalFormWithUkey();
  const isOnlyShowInfo = selectedService?.isOnlyShowInfo;

  const fieldsHeaderToPropagate = selectedService?.fieldsHeaderToPropagate;
  const hasFieldsToAddInHeaderToPropagate =
    !_.isNil(fieldsHeaderToPropagate) && !_.isEmpty(fieldsHeaderToPropagate);

  const requiredPropagatedFields = useMemo(() => {
    let result = [];
    if (hasFieldsToAddInHeaderToPropagate === true) {
      for (let i = 0; i < _.size(fieldsHeaderToPropagate); i++) {
        const pf = fieldsHeaderToPropagate[i];
        if (!_.isNil(pf) && !_.isEmpty(pf)) {
          const formSchemaDef = getFormSchemaDefinitionByCompleteIdControl({
            completeIdControl: pf,
            formSchema,
          });

          if (formSchemaDef?.propagateTo?.required === true) {
            result.push(pf);
          }
        }
      }
    }

    return result;
  }, [fieldsHeaderToPropagate, formSchema, hasFieldsToAddInHeaderToPropagate]);

  const [openConfirmOverrideDialog, setOpenConfirmOverrideDialog] =
    useState(false);

  useEffect(() => {
    if (auto) {
      handleOpen(0);
    }

    return () => null;
    // eslint-disable-next-line
  }, [auto]);

  useEffect(() => {
    const handler = (e) => {
      const { keyCode } = e;
      if (
        keyCode === KEY_ENTER &&
        tableRef &&
        tableRef.current &&
        rows &&
        rows.length > 0
      ) {
        e.preventDefault();
        const materialTable = tableRef.current;
        if (materialTable.dataManager && materialTable.dataManager.searched) {
          const searchedData = materialTable.dataManager.searchedData;
          if (
            searchedData &&
            searchedData.length > 0 &&
            searchedData.length === 1
          ) {
            const rowToSet = searchedData[0];
            if (rowToSet && rowToSet.tableData) {
              handleSetSelectedValues(rowToSet.tableData.id, false, null);
            }
          }
        }
      }
    };
    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    };
    // eslint-disable-next-line
  }, [tableRef, rows, searchingText]);

  const handleOpen = async (indexService) => {
    const selService = services[indexService];
    //If selected service is not null or undefined and its name too
    if (!_.isNil(selService) && !_.isNil(selService.serviceName)) {
      const { serviceName, params, dataResultDef, key, callServer } =
        selService;
      const replacedParamsWithValues = params;
      if (replacedParamsWithValues === false) {
        toast.error(t("FORM_INCOMPLETE_ENTRY"), {
          containerId: TOAST_CONTAINER_LAYOUT,
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      } else {
        if (
          _.isNil(callServer) ||
          (!_.isNil(callServer) && callServer === true)
        ) {
          setSelectedService(selService);
          setOpen(true);
          isFetching(true);

          const response = await handlersAPI.callSelectionService({
            serviceName,
            replacedParamsWithValues,
            dataResultDef,
            company: auth?.company?.codCompany,
            formInstanceId,
            key,
            line,
            REQUEST_HEADERS,
          });

          await analyzeExecuteSelectionResponse(response, dataResultDef);
        } else {
          if (serviceName === SEL_CLI_PASTE_AREA) {
            setSelectedService(selService);
            setOpenPasteArea(true);
            setOpen(true);
          }
        }
      }
    }
  };

  async function analyzeExecuteSelectionResponse(response, dataResultDef) {
    let toastContent = null;
    isFetching(false);
    if (response) {
      const { ok, errorMessage, dataResult } = response;
      if (ok) {
        if (dataResult && dataResult.length > 0) {
          const dataToSet = JSON.parse(dataResult);
          //Function to add row + column, for row number, in rows will be 1,2,3,etc and the column: Fila, Row, etc
          addRowNumberToTable(dataToSet, dataResultDef, setRows, setColumns);
        } else {
          setServiceErrorMessageNoData(errorMessage);
        }
      } else {
        if (errorMessage && errorMessage !== "") {
          toastContent = <CustomBodyToast title={errorMessage} msg={null} />;
        } else {
          toastContent = (
            <CustomBodyToast title={t("FORM_ERROR_ACTION")} msg={null} />
          );
        }
      }
    }

    //If something to notify, then throw toast
    if (toastContent !== null) {
      toast.error(toastContent, {
        containerId: TOAST_CONTAINER_LAYOUT,
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  //Close Menu and Modal
  const handleClose = () => {
    setValuesToSetFromPropagatedFields([]);
    closeMenu();
    setOpen(false);
  };

  //Handle index row selected
  const handleRowSelection = (selectedRow) => {
    if (isOnlyShowInfo) {
      return;
    }
    setSelectedRow(selectedRow);
  };

  //Handle data of row selected by choose button or double click on row
  const handleDataSelected = (
    isDoubleClick,
    globalGridAddMode,
    flatFieldHasValue,
    sendCompletedEndpoint
  ) => {
    if (
      mustThrowErrorByRequiredFields({
        requiredPropagatedFields,
        propagatedFields,
        hasFieldsToAddInHeaderToPropagate,
      })
    ) {
      setChooseClicked(true);
      return;
    } else {
      setChooseClicked(false);
    }

    if (isCalledFromGlobalFilterInGrid) {
      if (globalGridAddMode === GLOBAL_GRID_MODE_REPLACE) {
        handleSetSelectedValues(
          selectedMultipleRows,
          true,
          null,
          true,
          sendCompletedEndpoint
        );
      } else if (globalGridAddMode === GLOBAL_GRID_MODE_APPEND) {
        handleSetSelectedValues(
          selectedMultipleRows,
          true,
          null,
          false,
          flatFieldHasValue,
          sendCompletedEndpoint
        );
      } else {
        if (alreadyInfoInGrid || flatFieldHasValue?.hasValue === true) {
          setOpenConfirmOverrideDialog(true);
        } else {
          if (!_.isNil(selectedMultipleRows) && !isDoubleClick) {
            handleSetSelectedValues(
              selectedMultipleRows,
              true,
              null,
              undefined,
              undefined,
              sendCompletedEndpoint
            );
          } else if (!_.isNil(selectedRow)) {
            handleSetSelectedValues(
              selectedRow,
              false,
              null,
              undefined,
              undefined,
              sendCompletedEndpoint
            );
          }
        }
      }
    } else {
      if (!_.isNil(selectedMultipleRows) && !isDoubleClick) {
        handleSetSelectedValues(
          selectedMultipleRows,
          true,
          null,
          undefined,
          undefined,
          sendCompletedEndpoint
        );
      } else if (!_.isNil(selectedRow)) {
        handleSetSelectedValues(
          selectedRow,
          false,
          null,
          undefined,
          undefined,
          sendCompletedEndpoint
        );
      }
    }
  };

  function handleSetSelectedValues(
    rowId,
    multilpleRows,
    searchedMultiple,
    overrideRows,
    flatFieldHasValue,
    sendCompletedEndpoint
  ) {
    if (isOnlyShowInfo === true) {
      return;
    }

    const fieldsToReturn = selectedService?.toReturn;

    let valuesToCallService = null;

    if (multilpleRows) {
      if (searchedMultiple) {
        valuesToCallService = makeValuesToSetValuesMultiple(
          fieldsToReturn,
          searchedMultiple,
          line
        );
      } else {
        valuesToCallService = makeValuesToSetValuesMultiple(
          fieldsToReturn,
          selectedMultipleRows,
          line
        );
      }
    } else {
      const dataOfRow = rows && rows.length > 0 && rows[rowId];
      valuesToCallService = makeValuesToSetValues(
        fieldsToReturn,
        dataOfRow,
        line
      );
    }

    if (
      !_.isNil(valuesToSetFromPropagatedFields) &&
      _.isArray(valuesToSetFromPropagatedFields) &&
      !_.isEmpty(valuesToSetFromPropagatedFields)
    ) {
      const minLineInValues = _.minBy(valuesToCallService, "line");
      const maxLineInValues = _.maxBy(valuesToCallService, "line");

      let valueMaxLine = 0;
      let startLine = 1;

      if (!_.isNil(minLineInValues)) {
        startLine = minLineInValues?.line;
      }

      if (!_.isNil(maxLineInValues)) {
        valueMaxLine = maxLineInValues?.line;
      }

      if (valueMaxLine === 0 && startLine === 1) {
        for (const vs of valuesToCallService) {
          if (!_.isNil(vs) && !_.isNil(vs.line)) {
            // start line
            if (overrideRows === false && startLine === 1) {
              startLine = _.toSafeInteger(vs.line);
            }
            if (
              _.toSafeInteger(vs.line) <= startLine &&
              overrideRows === false
            ) {
              startLine = vs.line;
            }

            // max line
            if (_.toSafeInteger(vs.line) > valueMaxLine) {
              if (overrideRows) {
                valueMaxLine++;
              } else {
                valueMaxLine = vs.line;
              }
            }
          }
        }
      }

      const newArrayExpanded = expandArray(
        valuesToSetFromPropagatedFields,
        valueMaxLine,
        startLine
      );

      if (
        !_.isNil(newArrayExpanded) &&
        _.isArray(newArrayExpanded) &&
        !_.isEmpty(newArrayExpanded)
      ) {
        valuesToCallService.push(...newArrayExpanded);
      }
    }

    const flatFieldDef = selectedService?.flatFieldDef;
    if (
      !_.isNil(flatFieldDef) &&
      !_.isEmpty(flatFieldDef) &&
      !_.isNil(flatFieldDef.flatOuput) &&
      !_.isEmpty(flatFieldDef.flatOuput) &&
      _.isString(flatFieldDef.flatOuput) &&
      !_.isNil(valuesToCallService) &&
      !_.isEmpty(valuesToCallService) &&
      _.isArray(valuesToCallService)
    ) {
      let flattenedOutputToSet = flattenArrayToString(
        valuesToCallService,
        flatFieldDef?.fieldSeparator,
        flatFieldDef?.lineSeparator
      );

      if (
        !_.isNil(flatFieldHasValue) &&
        !_.isNil(flatFieldHasValue.value) &&
        flatFieldHasValue.hasValue === true
      ) {
        flattenedOutputToSet = `${flatFieldHasValue.value}${flatFieldDef?.lineSeparator}${flattenedOutputToSet}`;
      }

      if (!_.isNil(flattenedOutputToSet)) {
        valuesToCallService.push({
          idControl: flatFieldDef.flatOuput,
          line: 0,
          value: flattenedOutputToSet,
        });
      }

      if (flatFieldDef.ignoreSetGridData === true) {
        valuesToCallService = _.filter(valuesToCallService, function (o) {
          return !_.isNil(o) && o.line === 0;
        });
      }
    }

    if (!_.isNil(valuesToCallService)) {
      setValue(
        valuesToCallService,
        overrideRows,
        flatFieldDef?.ignoreSetGridData,
        sendCompletedEndpoint
      );
      if (!_.isNil(setComeFromSelection)) {
        setComeFromSelection(true);
      }
    }
    setPropagatedFields({});
    setValuesToSetFromPropagatedFields([]);
    setOpen(false);
    closeMenu();
  }

  const flatFieldHasValue = useMemo(() => {
    const invalidSS = _.isNil(selectedService);
    const invalidFFD = _.isNil(selectedService?.flatFieldDef);
    const invalidISGD =
      selectedService?.flatFieldDef?.ignoreSetGridData !== true;
    const invalidOutput = _.isNil(selectedService?.flatFieldDef?.flatOuput);

    if (invalidSS || invalidFFD || invalidISGD || invalidOutput) {
      return { hasValue: false, value: null };
    }

    const actualValueInForm = resolveFieldAgainstShadowStatus({
      field: selectedService?.flatFieldDef?.flatOuput,
      completeIdControl: selectedService?.flatFieldDef?.flatOuput,
      shadowStatus,
    });

    const resultValue =
      !_.isNil(actualValueInForm) &&
      actualValueInForm !== "" &&
      actualValueInForm !== " ";

    return { hasValue: resultValue, value: actualValueInForm };
  }, [selectedService, shadowStatus]);

  const getColumnsInTextPasted = useCallback(
    (textPastedValue, mustHaveCols) => {
      let result = [];
      if (
        !_.isNil(textPastedValue) &&
        !_.isEmpty(textPastedValue) &&
        _.toSafeInteger(mustHaveCols) > 0
      ) {
        const lines = _.filter(
          textPastedValue.split(LINE_SEPARATOR),
          function (o) {
            return !_.isNil(o) && !_.isEmpty(o);
          }
        );

        for (let i = 0; i < lines.length; i++) {
          const lineData = lines[i].split(FIELD_SEPARATOR);
          const lineDataLength = _.toSafeInteger(lineData?.length);

          if (lineDataLength !== mustHaveCols) {
            result.push(
              t("HANDLE_CONTEXT_MENU_COLS_NOT_MATCH", {
                lineNumber: i + 1,
                mustHaveCols,
                lineDataLength,
              })
            );
          }
        }
      }
      return result;
    },
    [t]
  );

  const handlePasteAreaAction = useCallback(
    async (copiedText, removeDuplicatedLines) => {
      const isTextWritten =
        !_.isNil(textPastedValue) && !_.isEmpty(textPastedValue);
      const isTextPasted = !_.isNil(copiedText) && !_.isEmpty(copiedText);
      if (
        ((isTextWritten && ENABLED_BUTTON_TO_ADD_PASTED === true) ||
          (isTextPasted && ENABLED_BUTTON_TO_ADD_PASTED === false)) &&
        !_.isNil(selectedService) &&
        !_.isEmpty(selectedService)
      ) {
        const textValueToUse =
          ENABLED_BUTTON_TO_ADD_PASTED === true ? textPastedValue : copiedText;
        const { dataResultDef, toReturn } = selectedService;
        if (!_.isNil(dataResultDef) && !_.isNil(toReturn)) {
          const neededColumns = dataResultDef.length;
          const columnsInTextPasted = getColumnsInTextPasted(
            textValueToUse,
            neededColumns
          );
          if (_.isEmpty(columnsInTextPasted)) {
            const dataToSet = parseTextAreaStringToArray(
              textValueToUse,
              dataResultDef,
              removeDuplicatedLines
            );

            addRowNumberToTable(dataToSet, dataResultDef, setRows, setColumns);
            setTextPastedValue("");
            setDebounceAllSelected(true);
          } else {
            const toastContent = (
              <ul style={{ maxHeight: 100, overflow: "auto" }}>
                {columnsInTextPasted.map((x, index) => (
                  <li key={index}>{x}</li>
                ))}
              </ul>
            );
            toast.error(toastContent, {
              containerId: TOAST_CONTAINER_LAYOUT,
              autoClose: false,
            });
          }
        }
      }
    },
    [getColumnsInTextPasted, selectedService, textPastedValue]
  );

  useEffect(() => {
    if (debounceAllSelected) {
      if (
        !_.isNil(tableRef) &&
        !_.isNil(tableRef.current) &&
        !_.isNil(tableRef.current.dataManager)
      ) {
        const actualRenderState = tableRef.current.dataManager.getRenderState();
        tableRef.current.setState(actualRenderState, () => {
          tableRef.current.onAllSelected(true);
        });
      }
      setDebounceAllSelected(false);
    }
    // eslint-disable-next-line
  }, [debounceAllSelected]);

  function handleDeletePastedText() {
    setTextPastedValue("");
    setColumns();
    setRows();
  }

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();

      const { current } = textAreaRef;
      const { selectionStart, selectionEnd, value } = current;

      // Insert a tab character at the current cursor position
      const newValue =
        value.substring(0, selectionStart) +
        "\t" +
        value.substring(selectionEnd);

      current.value = newValue;
      current.selectionStart = current.selectionEnd = selectionStart + 1;
    } else if (event.key === "Enter") {
      handlePasteAreaAction();
    }
  };

  const columnsToRender = useMemo(() => {
    const someRowHasToOpenForm =
      !_.isNil(rows) &&
      !_.isEmpty(rows) &&
      _.isArray(rows) &&
      _.some(rows, function (o) {
        return !_.isNil(o?.path) && !_.isNil(o?.idForm) && !_.isNil(o?.ukey);
      });
    const someRowHasToOpenHistory =
      !_.isNil(rows) &&
      !_.isEmpty(rows) &&
      _.isArray(rows) &&
      _.some(rows, function (o) {
        return !_.isNil(o?.taskId) || !_.isNil(o?.wfTaskId);
      });
    if (
      !_.isNil(columns) &&
      _.isArray(columns) &&
      !_.isEmpty(columns) &&
      (someRowHasToOpenForm === true || someRowHasToOpenHistory === true)
    ) {
      const openFormColumn = {
        align: "center",
        field: "openExternalForm",
        filtering: false,
        searchable: false,
        sorting: false,
        title: t("FORM_OPEN"),
        render: (rowData) => {
          const isEnabled =
            !_.isNil(rowData?.path) &&
            !_.isNil(rowData?.idForm) &&
            !_.isNil(rowData?.ukey);
          return (
            <Tooltip title={t("FORM_OPEN")}>
              <span>
                <IconButton
                  disabled={!isEnabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleOpenFormWithUkey({
                      ukey: rowData?.ukey,
                      path: rowData?.path,
                      idForm: rowData?.idForm,
                    });
                  }}
                >
                  <Icon
                    className={[
                      classes.iconExternal,
                      "fas fa-external-link-alt",
                    ].join(" ")}
                  />
                </IconButton>
              </span>
            </Tooltip>
          );
        },
      };

      const openHistoryColumn = {
        align: "center",
        field: "openExternalHistory",
        filtering: false,
        searchable: false,
        sorting: false,
        title: t("GEN_DASH_HISTORY"),
        render: (rowData) => {
          const isEnabled =
            !_.isNil(rowData?.taskId) || !_.isNil(rowData?.wfTaskId);

          return (
            <Tooltip title={t("GEN_DASH_HISTORY")}>
              <span>
                <IconButton
                  disabled={!isEnabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleOpenProcessHistoryDialog({
                      config: interactiveBtnDef?.historyBtnFrontConfig || [],
                      rowData,
                    });
                  }}
                >
                  <Icon
                    className={[classes.iconExternal, "far fa-clock"].join(" ")}
                  />
                </IconButton>
              </span>
            </Tooltip>
          );
        },
      };

      const columnsCopy = _.clone(columns);
      if (someRowHasToOpenForm === true && someRowHasToOpenHistory === true) {
        columnsCopy.splice(1, 0, openFormColumn);
        columnsCopy.splice(2, 0, openHistoryColumn);
      } else if (
        someRowHasToOpenForm === true &&
        someRowHasToOpenHistory === false
      ) {
        columnsCopy.splice(1, 0, openFormColumn);
      } else if (
        someRowHasToOpenForm === false &&
        someRowHasToOpenHistory === true
      ) {
        columnsCopy.splice(1, 0, openHistoryColumn);
      }
      return columnsCopy;
    } else {
      return columns;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, rows]);

  useEffect(() => {
    const hasPreloadedInfo =
      !_.isNil(preLoadedPasteInfo) && preLoadedPasteInfo?.resultString !== "";

    const hasTextValueSetted =
      !_.isNil(textPastedValue) && textPastedValue !== "";

    const hasRefInBtnToClick =
      !_.isNil(chargeDataBtnRef) && !_.isNil(chargeDataBtnRef.current);

    if (
      effectExecuted === false &&
      hasPreloadedInfo &&
      hasTextValueSetted &&
      hasRefInBtnToClick &&
      open
    ) {
      setEffectExecuted(true);
      setTimeout(() => {
        chargeDataBtnRef.current.click();
      }, 100);
    }

    if (
      effectExecuted === false &&
      hasPreloadedInfo &&
      hasTextValueSetted &&
      hasRefInBtnToClick === false &&
      open
    ) {
      handlePasteAreaAction(textPastedValue);
    }
  }, [
    preLoadedPasteInfo,
    textPastedValue,
    chargeDataBtnRef,
    effectExecuted,
    open,
    handlePasteAreaAction,
  ]);

  const changePropagatedFiles = useCallback(({ name, value }) => {
    setPropagatedFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const serviceHasPasteAreaHelp = !_.isNil(selectedService?.pasteAreaHelp);

  console.log({ openPasteArea });
  return (
    <>
      {!auto && (
        <Menu
          id={uniqueID}
          keepMounted
          open={menuPosition.y !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            menuPosition.y !== null && menuPosition.x !== null
              ? { top: menuPosition.y, left: menuPosition.x }
              : undefined
          }
        >
          {services.map((service, index) => {
            const disabledPartner =
              !_.isNil(disabledSelectionServicesDynamic) &&
              !_.isEmpty(disabledSelectionServicesDynamic)
                ? _.find(disabledSelectionServicesDynamic, function (o) {
                    return o?.key === service?.key;
                  })
                : null;
            const isDisabledByParam = !_.isNil(disabledPartner);
            return (
              <div key={index} style={{ padding: 5 }}>
                <Tooltip
                  title={disabledPartner?.disableInteractionText || ""}
                  disableHoverListener={!disabledPartner}
                >
                  <span>
                    <MenuItem
                      className={classes.serviceRow}
                      onClick={() => handleOpen(index)}
                      disabled={isDisabledByParam}
                    >
                      {service.displayName}
                    </MenuItem>
                  </span>
                </Tooltip>
              </div>
            );
          })}
        </Menu>
      )}
      <Dialog
        open={openConfirmOverrideDialog}
        aria-labelledby="override-confirm-dialog-title"
        aria-describedby="override-confirm-dialog-description"
      >
        <DialogTitle id="override-confirm-dialog-title">
          {t("HANDLE_CONTEXT_MENU_OVERRIDE_ROWS_TITLE")}
        </DialogTitle>
        <DialogActions className={classes.confirmOverrideDialogActions}>
          <Button
            onClick={() => {
              if (!_.isNil(selectedMultipleRows)) {
                handleSetSelectedValues(selectedMultipleRows, true, null, true, undefined, openPasteArea);
              } else {
                handleSetSelectedValues(selectedRow, false, null, true, undefined, openPasteArea);
              }
            }}
            color="primary"
          >
            {t("HANDLE_CONTEXT_MENU_OVERRIDE_ROWS_REPLACE")}
          </Button>
          <Button
            onClick={() => {
              if (!_.isNil(selectedMultipleRows)) {
                handleSetSelectedValues(
                  selectedMultipleRows,
                  true,
                  null,
                  false,
                  flatFieldHasValue,
                  openPasteArea
                );
              } else {
                handleSetSelectedValues(
                  selectedRow,
                  false,
                  null,
                  false,
                  flatFieldHasValue,
                  openPasteArea
                );
              }
            }}
            color="primary"
          >
            {t("HANDLE_CONTEXT_MENU_OVERRIDE_ROWS_ADD")}
          </Button>
          <Button onClick={() => setOpenConfirmOverrideDialog(false)}>
            {t("CANCEL")}
          </Button>
        </DialogActions>
      </Dialog>
      <ProcessHistoryDialog />
      <CustomDialog isOpen={open}>
        {fetching === true ? (
          <div className={classes.loadingContainer}>
            <CircularProgress style={{ color: theme.palette.text.primary }} />
          </div>
        ) : (
          <div className={classes.contentContainer}>
            <ExternalForm />
            <IconButton
              style={{
                position: "absolute",
                top: "3px",
                right: "5px",
                zIndex: 1,
                padding: 4,
              }}
              onClick={handleClose}
            >
              <Icon
                className="fas fa-times-circle"
                style={{
                  ...sharedIconStyles,
                  color: theme.palette.content.mainColor,
                  fontSize: 18,
                }}
              />
            </IconButton>

            {!openPasteArea && hasFieldsToAddInHeaderToPropagate && (
              <div className={classes.headerDivMultiple}>
                <HeaderFieldsToPropagate
                  fieldsHeaderToPropagate={fieldsHeaderToPropagate}
                  formSchema={formSchema}
                  formData={formData}
                  propagatedFields={propagatedFields}
                  changePropagatedFiles={changePropagatedFiles}
                  setValuesToSetFromPropagatedFields={
                    setValuesToSetFromPropagatedFields
                  }
                  requiredPropagatedFields={requiredPropagatedFields}
                  chooseClicked={chooseClicked}
                />
              </div>
            )}

            {/* HEADER DIV */}
            {openPasteArea && (
              <div
                className={
                  hasFieldsToAddInHeaderToPropagate
                    ? classes.headerDivMultiple
                    : classes.headerDiv
                }
              >
                {hasFieldsToAddInHeaderToPropagate && (
                  <HeaderFieldsToPropagate
                    fieldsHeaderToPropagate={fieldsHeaderToPropagate}
                    formSchema={formSchema}
                    formData={formData}
                    propagatedFields={propagatedFields}
                    changePropagatedFiles={changePropagatedFiles}
                    setValuesToSetFromPropagatedFields={
                      setValuesToSetFromPropagatedFields
                    }
                  />
                )}
                <HandlerContextMenuPasteAreaHelpDialog
                  open={showPasteAreaHelp}
                  pasteAreaHelp={selectedService?.pasteAreaHelp}
                  handleClose={() => setShowPasteAreaHelp(false)}
                />
                <FormControl
                  fullWidth
                  className={
                    hasFieldsToAddInHeaderToPropagate
                      ? ""
                      : classes.pasteAreaControl
                  }
                  variant="outlined"
                >
                  <OutlinedInput
                    inputRef={textAreaRef}
                    placeholder={selectedService?.displayName}
                    id="paste-area-text-field"
                    autoFocus
                    variant="outlined"
                    multiline
                    maxRows={30}
                    value={textPastedValue}
                    readOnly={ENABLED_BUTTON_TO_ADD_PASTED === false}
                    onPaste={(e) => {
                      if (ENABLED_BUTTON_TO_ADD_PASTED === false) {
                        e.preventDefault();
                        const text = e.clipboardData.getData("text/plain");
                        handlePasteAreaAction(
                          text,
                          selectedService?.removeDuplicatedLines
                        );
                      }
                    }}
                    onChange={(e) => setTextPastedValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    startAdornment={
                      <div>
                        {serviceHasPasteAreaHelp && (
                          <IconButton
                            onClick={() => setShowPasteAreaHelp(true)}
                          >
                            <Icon
                              className="fas fa-info-circle"
                              style={sharedIconStyles}
                            />
                          </IconButton>
                        )}
                        {ENABLED_BUTTON_TO_ADD_PASTED ? (
                          <InputAdornment position="start">
                            <IconButton
                              disabled={
                                _.isNil(textPastedValue) ||
                                _.isEmpty(textPastedValue)
                              }
                              variant="contained"
                              onClick={() => handlePasteAreaAction()}
                              ref={chargeDataBtnRef}
                            >
                              <Icon
                                className="fas fa-reply-all"
                                style={{
                                  ...sharedIconStyles,
                                  transform: "rotate(270deg)",
                                }}
                                fontSize="small"
                              />
                            </IconButton>
                          </InputAdornment>
                        ) : null}
                      </div>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          disabled={
                            _.isNil(textPastedValue) ||
                            _.isEmpty(textPastedValue)
                          }
                          variant="contained"
                          onClick={() => handleDeletePastedText()}
                        >
                          <Icon
                            className="fas fa-trash"
                            style={sharedIconStyles}
                            fontSize="small"
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            )}

            {/* DATA DIV */}
            <div className={classes.dataDiv}>
              {!_.isNil(columnsToRender) &&
              !_.isNil(rows) &&
              !_.isEmpty(columnsToRender) &&
              !_.isEmpty(rows) ? (
                <MaterialTable
                  tableRef={tableRef}
                  localization={tableLocale}
                  columns={columnsToRender.map((c) => ({
                    ...c,
                    tableData: undefined,
                  }))}
                  style={{
                    width: "100%",
                    marginTop: 20,
                  }}
                  data={rows}
                  title={openPasteArea ? "" : selectedService?.displayName}
                  onRowClick={(evt, selectedRow) => {
                    handleRowSelection(selectedRow.tableData.id);
                  }}
                  onSearchChange={(text) => {
                    setSarchingText(text);
                  }}
                  onSelectionChange={(rows) => {
                    setSelectedMultipleRows(rows);
                  }}
                  components={{
                    Row: (props) => (
                      <MTableBodyRow
                        {...props}
                        onDoubleClick={() =>
                          handleDataSelected(
                            true,
                            selectedService?.globalGridAddMode
                          )
                        }
                      />
                    ),
                    Cell: (props) => {
                      const width = props?.columnDef?.tableData?.width
                        ? toString(props?.columnDef?.tableData?.width).replace(
                            "%",
                            "vw"
                          )
                        : null;
                      const value = props?.value;
                      const clonedProps = {
                        ...props,
                        value: null,
                        columnDef: {
                          ...props?.columnDef,
                          cellStyle: {
                            ...props?.columnDef?.cellStyle,
                            textWrap: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: width,
                            width,
                          },
                        },
                      };

                      return <MTableCell {...clonedProps}>{value}</MTableCell>;
                    },
                  }}
                  icons={{ Clear: null }}
                  options={{
                    selection:
                      selectedService &&
                      ((selectedService?.canSelectMultipleLine &&
                        selectedService?.canSelectMultipleLine === true) ||
                        selectedService?.serviceName === SEL_CLI_PASTE_AREA),
                    searchAutoFocus: wd?.width > BREAKPOINT_LG,
                    search: true,
                    maxBodyHeight: "50vh",
                    pageSize: 20,
                    pageSizeOptions: [20, 50, 100],
                    headerStyle: {
                      backgroundColor: theme.palette.content.mainColor,
                      color: "white",
                      //padding: "5px 5px",
                      position: "sticky",
                    },
                    rowStyle: (rowData) => ({
                      color: theme.palette.text.primary,
                      backgroundColor:
                        selectedRow === rowData.tableData.id
                          ? theme.palette.table.selectedBackground
                          : theme.palette.table.background,
                      fontWeight:
                        selectedRow === rowData.tableData.id
                          ? "bold"
                          : "normal",
                      padding: "0px 10px",
                    }),
                  }}
                />
              ) : openPasteArea ? null : (
                <NothingToShow msg={serviceErrorMessageNoData} />
              )}
            </div>

            {/* FOOTER DIV */}
            <div className={classes.footerDiv}>
              {chooseClicked ? (
                <Alert severity="error" variant="filled">
                  {t("FORM_INCOMPLETE_ENTRY")}
                </Alert>
              ) : (
                <div />
              )}

              <div className={classes.footerDivEnd}>
                {(selectedRow !== null ||
                  (!_.isNil(selectedMultipleRows) &&
                    !_.isEmpty(selectedMultipleRows))) && (
                  <div className={classes.footerDivInternal}>
                    <button
                      className={classes.buttonTransformHover}
                      onClick={() =>
                        handleDataSelected(
                          false,
                          selectedService?.globalGridAddMode,
                          flatFieldHasValue,
                          openPasteArea
                        )
                      }
                    >
                      <p>{t("FORM_SELECT")}</p>
                    </button>
                  </div>
                )}
                <button
                  className={classes.buttonTransformHoverCancel}
                  onClick={handleClose}
                >
                  <p>{t("FORM_CANCEL")}</p>
                </button>
              </div>
            </div>
          </div>
        )}
      </CustomDialog>
    </>
  );
}
