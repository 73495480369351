import { useEffect, useState } from "react";
import { format } from "date-fns";
import { FRONT_TIME_SECONDS_PATTERN } from "../../util/Constants";

function getHumanReadableTimeOffset() {
  try {
    const offsetInMinutes = new Date().getTimezoneOffset();

    const sign = offsetInMinutes > 0 ? "-" : "+";
    const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
    const offsetString = "GMT" + sign + hours;

    return offsetString;
  } catch (e) {
    return null;
  }
}

export const useLiveClock = () => {
  const [time, setTime] = useState(
    format(new Date(), FRONT_TIME_SECONDS_PATTERN)
  );

  const timeOffset = getHumanReadableTimeOffset();

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(format(new Date(), FRONT_TIME_SECONDS_PATTERN));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return { time, timeOffset };
};
