import * as Constants from "../../util/Constants";

const MAIN_COLOR = "#4AA0DE";
const MAIN_DARK_COLOR = "#06283D";

const SECOND_DARK_COLOR = "#111634";
const TEXT_PRIMARY = "#FFFFFF";

const FORM_BACKGROUND = "#2E2D2E";

export const dark = {
  typography: {
    fontFamily: ["Asap", "sans-serif"].join(" , "),
    fontSize: 11,
  },
  overrides: {
    MuiTablePagination: {
      caption: {
        fontWeight: "bold",
      },
    },
    MuiSelect: {
      filled: {
        //backgroundColor: "#00b8a955",
        backgroundColor: `${MAIN_COLOR}55`,
        //color: "#FFFFFF",
        //borderBottom: "1px solid #FFFFFF",
      },
      // iconFilled: {
      //   color: "#FFFFFF",
      // },
    },
    MuiCheckbox: {
      colorSecondary: {
        "&.Mui-checked": {
          color: "inherit",
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: "5px",
      },
      sizeSmall: {
        padding: "5px",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: 0,
      },
    },
    MuiDialogTitle: {
      root: {
        fontSize: "3em",
      },
    },
    MuiTableHead: {
      root: {
        border: `0px solid`,
      },
    },
    MuiTableSortLabel: {
      root: {
        "&:hover": {
          textDecoration: "underline",
        },
        "&:active": {
          color: "#FFFFFF !important",
          fontWeight: "bold",
          textDecoration: "underline",
        },
      },
      active: {
        fontWeight: "bold",
        textDecoration: "underline",
      },
      iconDirectionDesc: {
        color: "#FFFFFF !important",
        fontSize: 22,
        animation: "moveDown 2s infinite",
      },
      iconDirectionAsc: {
        color: "#FFFFFF !important",
        fontSize: 22,
        animation: "moveUp 2s infinite",
      },
    },
    MuiCssBaseline: {
      "@global": {
        // fieldset: {
        //   borderColor: MAIN_COLOR,
        //   borderRadius: "10px",
        //   height: "-webkit-fill-available",
        // },
        // legend: {
        //   fontSize: "1.25rem",
        //   fontWeight: "500",
        //   lineHeight: "1.6",
        //   letterSpacing: "0.0075em",
        //   color: MAIN_COLOR,
        // },
        // ".form-control": {
        //   border: `0.5px solid ${MAIN_COLOR}`,
        //   borderRadius: "5px",
        //   fontFamily: ["Asap", "sans-serif"].join(" , "),
        // },
        // "input:disabled": {
        //   backgroundColor: "inherit",
        // },
        "*::-webkit-scrollbar": {
          height: "1em", //horizontal scroll bar height
          width: "0.7em", // vertical scroll bar width
          backgroundColor: "#F7F6F633",
          borderRadius: 15,
        },
        "*::-webkit-scrollbar-track": {
          "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
          borderRadius: 15,
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: `${MAIN_COLOR}AA`,
          borderRadius: 15,
        },
      },
    },
  },
  palette: {
    type: "dark",
    container: {
      backgroundColor: Constants.BACKGROUND_ALWAYS_COLOR,
    },
    form: {
      background: `${FORM_BACKGROUND}`,
      inputBorder: MAIN_COLOR,
      inputBorderRadius: "5px",
      inputFontFamily: ["Asap", "sans-serif"].join(" , "),
      selectionItemBorder: MAIN_COLOR,
      selectionItemText: "#FFFFFF",
      labelTextColor: "#FFFFFF",
      borderBlock: `${MAIN_COLOR}`,
      bgBlock: `${MAIN_COLOR}11`,
      headerBg: `${MAIN_COLOR}22`,
    },
    content: {
      backgroundColor: `${MAIN_DARK_COLOR}`,
      mainColor: MAIN_COLOR,
      oppositeMainColor: "#7471CA",
      titleTextColor: "#FFFFFF",
      titleTextShadow: "#6B6B6B",
      mainColorContrastText: "#FFFFFF",
      cardsBackground: MAIN_DARK_COLOR,
      niceRed: "#d63031",
      dashboardFetchingBackground: "#2d3436",
      tabItemBg:
        "linear-gradient(180deg, rgba(20,64,93,1) 11%, rgba(30,96,140,0.8993930335806197) 100%)",
      tabItemActiveBg:
        "linear-gradient(180deg, rgb(26 84 123) 11%, rgb(25 123 188 / 90%) 100%)",
    },
    drawer: {
      item: MAIN_COLOR,
      child: "",
      grand: "",
      grandSelected: MAIN_DARK_COLOR,
      backgroundColor: SECOND_DARK_COLOR,
      childBackgroundColor: "#4E64EC",
      iconSize: 16,
      upGradientColor: "rgba(17,22,52,1)",
      downGradientColor: "rgba(23,31,82,1)",
      refreshButtonBackgroundColor: MAIN_COLOR,
    },
    header: {
      formFlagBackground: SECOND_DARK_COLOR,
      textColor: "#FFFFFF",
      iconColor: "#FFFFFF",
      backgroundColor: SECOND_DARK_COLOR,
    },
    title: {
      formFlagBackground: SECOND_DARK_COLOR,
      textColor: "#FFFFFF",
      iconColor: "#FFFFFF",
      backgroundColor: SECOND_DARK_COLOR,
    },

    footer: {
      main: Constants.DARK_COLOR_MID_DARK,
    },
    common: {
      black: "#000000",
      white: "#FFFFFF",
    },

    primary: {
      main: MAIN_COLOR,
      light: Constants.DARK_COLOR_LIGHTEST,
      dark: Constants.DARK_COLOR_DARKEST,
    },
    secondary: {
      main: MAIN_COLOR,
    },
    children: {
      main: "rgba(255, 255, 255, 0.12)",
    },
    text: {
      primary: TEXT_PRIMARY,
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)",
      hint: "rgba(255, 255, 255, 0.5)",
      icon: "rgba(255, 255, 255, 0.12)",
      hover: "#565656",
    },
    table: {
      header: {
        backgroundColor: "#323743",
        textColor: MAIN_COLOR,
        borderColor: MAIN_COLOR,
      },
      background: "#323743",
      selectedBackground: `${MAIN_COLOR}33`,
      selectedBackgroundGrouped: "#D3D3D3",
    },
    genericTablePabel: {
      decisionButtonBackgroundColor: TEXT_PRIMARY,
      panelConfigColumnBackground: "#808080",
      panelConfigColumnBackgroundS: "#D3D3D3",
    },
    tableNew: {
      header: {
        color: "#FFFFFF",
        backgroundColor: MAIN_COLOR,
        fontWeight: "bold",
        borderBottom: MAIN_COLOR,
        whiteSpace: "nowrap",
      },
    },
    home: {
      fabMainBg: "#D22779",
    },
    card: {
      border: "#6b6b6b",
    },
    semaphore: {
      red: "#EB4747",
      yellow: "#a0a001",
      green: "#00a46e",
      orange: "#FF8F00",
    },
  },
  transitions: {
    duration: {
      leavingScreen: 300,
      enteringScreen: 300,
    },
  },
};
