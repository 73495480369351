import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import * as _ from "lodash";
import * as jwt from "jsonwebtoken";
import { useTranslation } from "react-i18next";
import { getUserLocale } from "get-user-locale";
import { useMsal } from "@azure/msal-react";

import * as Constants from "../../util/Constants";
import {
  LIGHT_MODE,
  THEME_PROP,
  USER_PROP,
  LOCALE_PROP,
} from "../../util/Constants";
import { useLocale } from "../hooks/useLocale";

import { DrawerContext } from "./DrawerContext";
import { ThemeContext } from "./ThemeContext";
import { refreshPage } from "../../util/util-io";
import { getTimeOffsetInHours } from "../../util/UtilDates";
import usePersistentStore from "../stores/PersistentStore";

const LOGIN_METHOD = "/login";
const VALIDATE_MFA_METHOD = "/validateMfaCode";
const LOGIN_MAD_METHOD = "/loginMAD";

const CHANGE_COMP_METHOD = "/ccomp";
const CHANGE_LANG_METHOD = "/changeLang";
const CHANGE_PASS_METHOD = "/changePassword";
const FORGOT_PASS_METHOD = "/forgotPassword";
const VALIDATE_CAPTCHA_METHOD = "/validate-captcha";

//Initial state context
const initialContextState = {
  userName: "",
  token: null,
  isLogged: false,
  roles: [],
  company: null,
  companies: [],
  remember: false,
  isLoading: true,
  mustChangePass: false,
  isLoginMad: false,
};

//Creating the context
const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const [auth, setAuth] = useState(initialContextState);
  const { setTheme } = useContext(ThemeContext);
  const { returnToInitialState } = useContext(DrawerContext);
  const [, changeLocale] = useLocale();
  const userLocale = getUserLocale();
  const { instance, accounts } = useMsal();
  const setThrowToast = usePersistentStore((state) => state.setThrowToast);

  const REQUEST_HEADERS = {
    ...Constants.REST_HEADERS,
    [Constants.TOKEN_HEADER]: `${Constants.TOKEN_INIT}${auth.token}`,
    [Constants.TIME_OFFSET_HEADER]: getTimeOffsetInHours(),
    [Constants.USER_LOCALE_HEADER]: userLocale,
  };

  const detectedLanguage = navigator.language || navigator.userLanguage;

  useEffect(() => {
    window.addEventListener("storage", handleStorageTokenChange);

    return () => {
      window.removeEventListener("storage", handleStorageTokenChange);
    };
  }, []);

  function handleStorageTokenChange(e) {
    if (
      e.key === USER_PROP &&
      !_.isNil(e.newValue) &&
      e.newValue !== e.oldValue
    ) {
      //new user login in other tab
      refreshPage();
    } else if (_.isNil(e.key) && _.isNil(e.newValue) && _.isNil(e.oldValue)) {
      //logout in other tab
      refreshPage();
    }
  }

  //Function to handle login
  async function login({ userName, password, remember }) {
    const response = await authenticateToServer({
      userName,
      password,
      detectedLanguage,
    });

    if (!_.isNil(response)) {
      const { ok, companies, token, locale, mustChangePass, status } = response;

      if (status === Constants.HTTP_STATUS_TOO_MANY_REQUEST) {
        return {
          ok: false,
          msg: t("ERROR_TOO_MANY_REQUEST"),
        };
      } else {
        if (ok) {
          const defaultCompany =
            companies &&
            companies.length > 0 &&
            companies.find((c) => c.defaultCompany === true);

          const newState = {
            ...initialContextState,
            userName: userName.toUpperCase(),
            isLogged: true,
            company: defaultCompany || null,
            companies: companies,
            remember,
            token,
            isLoading: false,
            mustChangePass,
          };

          if (remember) {
            window.localStorage.setItem(USER_PROP, JSON.stringify(newState));
          }

          setAuth(newState);
          changeLocale(locale);

          return response;
        } else {
          changeLocale(locale);
          return response;
        }
      }
    } else {
      return {
        ok: false,
        msg: t("ERROR_RESOURCE_NOT_FOUND_TEXT"),
      };
    }
  }

  async function logout(throwToast) {
    if (accounts && accounts.length > 0) {
      await instance.logoutPopup();
    }

    const userTheme = window.localStorage.getItem(THEME_PROP);
    const localeTheme = window.localStorage.getItem(LOCALE_PROP);

    window.localStorage.clear();

    //Return drawer to initial state
    returnToInitialState();

    //Return to theme
    window.localStorage.setItem(THEME_PROP, userTheme || LIGHT_MODE);
    setTheme(userTheme);

    //Return to locale
    window.localStorage.setItem(LOCALE_PROP, localeTheme);
    changeLocale(localeTheme);

    //Remove user props from local storage and set not auth
    if (throwToast !== undefined && throwToast !== null) {
      setThrowToast(true);
      setAuth({ ...initialContextState, isLoading: false });
    } else {
      setAuth({ ...initialContextState, isLoading: false });
    }

    setTimeout(() => {
      window.location.reload();
    }, 300);
  }

  async function loginMAD({ name, username, tenantId, remember, mail }) {
    const response = await authenticateMADToServer({
      name,
      username,
      tenantId,
      mail,
    });

    if (response && response.ok) {
      const { companies, token, locale } = response;
      const defaultCompany =
        companies &&
        companies.length > 0 &&
        companies.find((c) => c.defaultCompany === true);
      const newState = {
        ...initialContextState,
        userName: username.toUpperCase(),
        isLogged: true,
        company: defaultCompany || null,
        companies: companies,
        remember,
        token,
        isLoading: false,
        isLoginMad: true,
      };

      if (remember) {
        window.localStorage.setItem(USER_PROP, JSON.stringify(newState));
      }
      setAuth(newState);
      changeLocale(locale);

      return response;
    } else if (
      response &&
      !_.isNil(response.ok) &&
      response.ok === false &&
      !_.isNil(response.msg)
    ) {
      return response;
    } else {
      return {
        ok: false,
        msg: t("ERROR_RESOURCE_NOT_FOUND_TEXT"),
      };
    }
  }

  useEffect(() => {
    const cachedSession = window.localStorage.getItem(USER_PROP);

    if (cachedSession) {
      const cachedObj = JSON.parse(cachedSession);
      const { token } = cachedObj;
      const decoded = jwt.decode(token, { complete: true });

      if (decoded && decoded.payload && decoded.payload.exp) {
        const {
          payload: { exp },
        } = decoded;
        const now = new Date();
        const expiredDate = new Date(exp * 1000);

        if (expiredDate.getTime() > now.getTime()) {
          setAuth({
            ...cachedObj,
            isLoading: false,
          });
        } else {
          setThrowToast(true);
          setAuth({
            ...initialContextState,
            isLoading: false,
          });
        }
      } else {
        setAuth({
          ...initialContextState,
          isLoading: false,
        });
      }
    } else {
      setAuth({
        ...initialContextState,
        isLoading: false,
      });
    }
    // eslint-disable-next-line
  }, []);

  //Call authenticate method to server
  const authenticateToServer = async ({
    userName,
    password,
    detectedLanguage,
  }) => {
    const url = `${Constants.SERVER_HOST()}${Constants.API_FORM_SECURITY}${LOGIN_METHOD}?detectedLanguage=${detectedLanguage} `;

    const authBody = {
      userId: userName,
      password,
    };

    const response = await fetch(url, {
      body: JSON.stringify(authBody),
      method: Constants.METHOD_POST,
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-type": "application/json",
        [Constants.TIME_OFFSET_HEADER]: getTimeOffsetInHours(),
        [Constants.USER_LOCALE_HEADER]: userLocale,
      },
    })
      .then(async (res) => {
        if (res.status !== Constants.HTTP_STATUS_TOO_MANY_REQUEST) {
          const data = await res.json();
          if (!_.isNil(data) && !_.isEmpty(data)) {
            return { status: res.status, ...data };
          } else {
            return { status: res.status };
          }
        } else {
          return { status: res.status };
        }
      })
      .catch((error) => {
        return { ok: false, msg: error?.message };
      });

    return response;
  };

  const validateMFAServer = async ({ userName, password, mfaCode }) => {
    const url = `${Constants.SERVER_HOST()}${Constants.API_FORM_SECURITY}${VALIDATE_MFA_METHOD}?mfaCode=${mfaCode}`;

    const authBody = {
      userId: userName,
      password,
    };

    const response = await fetch(url, {
      body: JSON.stringify(authBody),
      method: Constants.METHOD_POST,
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-type": "application/json",
        [Constants.TIME_OFFSET_HEADER]: getTimeOffsetInHours(),
        [Constants.USER_LOCALE_HEADER]: userLocale,
      },
    })
      .then(async (res) => {
        if (res.status !== Constants.HTTP_STATUS_TOO_MANY_REQUEST) {
          const data = await res.json();
          if (!_.isNil(data) && !_.isEmpty(data)) {
            return { status: res.status, ...data };
          } else {
            return { status: res.status };
          }
        } else {
          return { status: res.status };
        }
      })
      .catch((error) => {
        return { ok: false, msg: error?.message };
      });

    return response;
  };

  const authenticateMADToServer = async ({
    name,
    username,
    tenantId,
    mail,
  }) => {
    const url =
      Constants.SERVER_HOST() + Constants.API_FORM_SECURITY + LOGIN_MAD_METHOD;

    const authBody = { name, username, tenantId, mail };

    const response = await fetch(url, {
      body: JSON.stringify(authBody),
      method: Constants.METHOD_POST,
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-type": "application/json",
        [Constants.TIME_OFFSET_HEADER]: getTimeOffsetInHours(),
        [Constants.USER_LOCALE_HEADER]: userLocale,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error;
      });

    return response;
  };

  async function changeCompany(comp) {
    const response = await notifyServerChangeCompany(comp.codCompany);

    if (response) {
      const { ok, dataResponse, status } = response;

      if (status === Constants.HTTP_STATUS_UNAUTHORIZED) {
        logout(true);
      } else if (status === Constants.HTTP_STATUS_NOT_FOUND) {
        return {
          ok: false,
          msg: t("ERROR_RESOURCE_NOT_FOUND_TEXT"),
        };
      } else {
        if (ok) {
          const newAuthState = {
            ...auth,
            token: dataResponse,
            company: comp,
            isLoading: false,
          };

          if (auth.remember) {
            window.localStorage.setItem(
              USER_PROP,
              JSON.stringify(newAuthState)
            );
          }
          setAuth(newAuthState);
        }
      }

      return response;
    } else {
      return {
        ok: false,
        msg: t("ERROR_RESOURCE_NOT_FOUND_TEXT"),
      };
    }
  }

  async function notifyServerChangeCompany(newComp) {
    const url =
      Constants.SERVER_HOST() +
      Constants.API_FORM_SECURITY +
      CHANGE_COMP_METHOD +
      `?newComp=${newComp}`;

    const response = await fetch(url, {
      method: Constants.METHOD_POST,
      headers: REQUEST_HEADERS,
    })
      .then((res) => res.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error;
      });

    return response;
  }

  async function changeLang(newLang) {
    const response = await notifyServerChangeLang(newLang);

    if (response) {
      const { ok, dataResponse, status } = response;
      if (status === Constants.HTTP_STATUS_UNAUTHORIZED) {
        logout(true);
      } else if (status === Constants.HTTP_STATUS_NOT_FOUND) {
        return {
          ok: false,
          msg: t("ERROR_RESOURCE_NOT_FOUND_TEXT"),
        };
      } else {
        if (ok) {
          const newAuthState = {
            ...auth,
            token: dataResponse,
            isLoading: false,
          };

          if (auth.remember) {
            window.localStorage.setItem(
              USER_PROP,
              JSON.stringify(newAuthState)
            );
          }
          setAuth(newAuthState);
          changeLocale(newLang);
        }
      }

      return response;
    } else {
      return {
        ok: false,
        msg: t("ERROR_RESOURCE_NOT_FOUND_TEXT"),
      };
    }
  }

  async function notifyServerChangeLang(newLang) {
    const url =
      Constants.SERVER_HOST() +
      Constants.API_FORM_SECURITY +
      CHANGE_LANG_METHOD +
      `?newLang=${newLang}`;

    const response = await fetch(url, {
      method: Constants.METHOD_POST,
      headers: REQUEST_HEADERS,
    })
      .then((res) => res.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error;
      });

    return response;
  }

  function renewToken(newToken) {
    if (newToken) {
      const newAuthState = {
        ...auth,
        token: newToken,
      };

      setAuth(newAuthState);
      window.localStorage.setItem(USER_PROP, JSON.stringify(newAuthState));
    }
  }

  async function validateMFA({
    userName,
    password,
    mfaCode,
    remember,
    mustChangePass,
  }) {
    const response = await validateMFAServer({
      userName,
      password,
      mfaCode,
    });

    if (!_.isNil(response)) {
      const { ok, companies, token, locale, status } = response;

      if (status === Constants.HTTP_STATUS_TOO_MANY_REQUEST) {
        return {
          status,
          ok: false,
          msg: t("ERROR_TOO_MANY_REQUEST"),
        };
      } else {
        if (ok) {
          const defaultCompany =
            companies &&
            companies.length > 0 &&
            companies.find((c) => c.defaultCompany === true);

          const newState = {
            ...initialContextState,
            userName: userName,
            isLogged: true,
            company: defaultCompany || null,
            companies: companies,
            remember,
            token,
            isLoading: false,
            mustChangePass,
          };

          if (remember) {
            window.localStorage.setItem(USER_PROP, JSON.stringify(newState));
          }

          setAuth(newState);
          changeLocale(locale);

          return response;
        } else {
          changeLocale(locale);
          return response;
        }
      }
    } else {
      return {
        ok: false,
        msg: t("ERROR_RESOURCE_NOT_FOUND_TEXT"),
      };
    }
  }

  async function changePassword(body) {
    const url =
      Constants.SERVER_HOST() + Constants.API_FORM_SECURITY + CHANGE_PASS_METHOD;

    const response = await fetch(url, {
      method: Constants.METHOD_POST,
      body: JSON.stringify(body),
      headers: REQUEST_HEADERS,
    })
      .then((res) => res.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error;
      });

    if (response && response?.ok && body && body?.isAutoChange) {
      const newState = {
        ...auth,
        mustChangePass: false,
      };
      setAuth(newState);
      window.localStorage.setItem(
        USER_PROP,
        JSON.stringify(newState)
      );
    }

    return response;
  }

  async function forgotPassword(body) {
    const url =
      Constants.SERVER_HOST() + Constants.API_FORM_SECURITY + FORGOT_PASS_METHOD;

    const response = await fetch(url, {
      method: Constants.METHOD_POST,
      body: JSON.stringify(body),
      headers: REQUEST_HEADERS,
    })
      .then(async (res) => {
        if (res.status !== Constants.HTTP_STATUS_TOO_MANY_REQUEST) {
          const data = await res.json();
          if (!_.isNil(data) && !_.isEmpty(data)) {
            return { status: res.status, ...data };
          } else {
            return { status: res.status };
          }
        } else {
          return { status: res.status };
        }
      })
      .catch((error) => {
        return { ok: false, msg: error?.message };
      });

    return response;
  }

  async function validateCaptcha(body) {
    const url =
      Constants.SERVER_HOST() +
      Constants.API_FORM_SECURITY +
      VALIDATE_CAPTCHA_METHOD;

    const response = await fetch(url, {
      method: Constants.METHOD_POST,
      body: JSON.stringify(body),
      headers: REQUEST_HEADERS,
    })
      .then((res) => res.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error;
      });

    return response;
  }

  const value = useMemo(
    () => ({
      auth,
      login,
      logout,
      changeCompany,
      changeLang,
      renewToken,
      loginMAD,
      validateMFA,
      changePassword,
      forgotPassword,
      validateCaptcha,
    }),
    // eslint-disable-next-line
    [auth]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

//Better exporting all things at bottom when we have more tan one
export { AuthContext, AuthContextProvider };
