import React, { useState, useRef, useContext, useEffect } from "react";

import { CSSTransition } from "react-transition-group";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  ClickAwayListener,
  Hidden,
  Icon,
  IconButton,
  Radio,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import { toast } from "react-toastify";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "./transitions.css";
import { languages } from "../../../../../util/Constants";
import {
  itemIconStart,
  useStyles,
  flagIConStart,
  itemIconEnd,
  commonIcon,
} from "./DropDownSettingsStyles";
import { ThemeContext } from "../../../../../core/providers/ThemeContext";
import { AuthContext } from "../../../../../core/providers/AuthContext";
import { useLocale } from "../../../../../core/hooks/useLocale";
import { TOAST_CONTAINER_LAYOUT } from "../../../../../util/Constants";
import LeaveWorkConfirmationDialog from "../../../../../components/dialogs/leaveWork/LeaveWorkConfirmationDialog";
import { refreshPage } from "../../../../../util/util-io";
import { NotificationsContext } from "../../../../../core/providers/NotificationsContext";
import ChangePassDialog from "../../../../../components/dialogs/changePassDialog/ChangePassDialog";
import { encryptPassword } from "../../../../../util/UtilFormat";
import { isArray, isEmpty, isNil, some } from "lodash";

function DropDownSettings({ setOpenLogoutConfirmation, handleComboCompany }) {
  const { auth } = useContext(AuthContext);
  const { mustChangePass, isLoginMad } = auth;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (mustChangePass && !isNil(isLoginMad) && isLoginMad === false) {
      setOpen(true);
    }
  }, [mustChangePass, isLoginMad]);

  function toggleDropDown(open) {
    setOpen(open);
  }

  return (
    <Navbar>
      <NavItem open={open} toggleDropDown={toggleDropDown}>
        <DropdownMenu
          toggleDropDown={toggleDropDown}
          setOpenLogoutConfirmation={setOpenLogoutConfirmation}
          handleComboCompany={handleComboCompany}
          mustChangePass={mustChangePass}
        />
      </NavItem>
    </Navbar>
  );
}

function Navbar(props) {
  const classes = useStyles();
  return (
    <nav>
      <ul className={classes.navBar}>{props.children}</ul>
    </nav>
  );
}

function NavItem({ open, toggleDropDown, children }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={() => toggleDropDown(false)}>
      <li className={classes.navItem}>
        <Tooltip title={t("GENERAL_CONFIG")}>
          <IconButton
            className={open ? classes.dropdownOpened : classes.dropdownClosed}
            onClick={() => toggleDropDown(!open)}
            style={{ padding: 7 }}
          >
            <Icon className={"fas fa-cog"} style={commonIcon} />
          </IconButton>
        </Tooltip>

        {open && children}
      </li>
    </ClickAwayListener>
  );
}

function DropdownMenu(props) {
  const {
    setOpenLogoutConfirmation,
    handleComboCompany,
    toggleDropDown,
    mustChangePass,
  } = props;
  const classes = useStyles();
  const muiTheme = useTheme();
  const { t } = useTranslation();
  const dropdownRef = useRef(null);
  const { auth, changeLang, changePassword } = useContext(AuthContext);
  const { companies, isLoginMad } = auth;

  const [locale] = useLocale();
  const [activeMenu, setActiveMenu] = useState("main");
  const [openLeaveWorkDialog, setOpenLeaveWorkDialog] = useState({
    open: false,
    newLang: null,
  });
  const [openLeaveWorkDialogClient, setOpenLeaveWorkDialogClient] = useState({
    open: false,
    newCompany: null,
  });

  const [openChangePasswordDialog, setOpenChangePasswordDialog] =
    useState(mustChangePass);

  const { toggleTheme, isLightMode } = useContext(ThemeContext);

  const { handleNotificationsDock, newVersion } =
    useContext(NotificationsContext);

  function handleOpenLeaveWorkDialog(newLang) {
    setOpenLeaveWorkDialog({ open: true, newLang });
  }
  async function handleOpenLeaveWorkConfDialogDecision(execute) {
    setOpenLeaveWorkDialog({ open: false, newLang: null });
    if (execute && openLeaveWorkDialog && openLeaveWorkDialog?.newLang) {
      handleChangeLang(openLeaveWorkDialog.newLang);
    }
  }

  function handleOpenLeaveWorkDialogClient(newCompany) {
    setOpenLeaveWorkDialogClient({ open: true, newCompany });
  }

  async function handleOpenLeaveWorkDialogClientDecision(execute) {
    setOpenLeaveWorkDialogClient({ open: false, newCompany: null });
    if (
      execute &&
      openLeaveWorkDialogClient &&
      openLeaveWorkDialogClient?.newCompany
    ) {
      //call change client
      await handleComboCompany(openLeaveWorkDialogClient?.newCompany);
      toggleDropDown(false);
      refreshPage();
    }
  }

  async function handleChangeLang(valueToSet) {
    const { ok, msg } = await changeLang(valueToSet);
    if (ok) {
      toggleDropDown(false);
      refreshPage();
    } else {
      toast.error(msg, {
        containerId: TOAST_CONTAINER_LAYOUT,
        position: "bottom-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function handleToogleTheme() {
    toggleTheme();
    toggleDropDown(false);
  }

  async function handleChangePass({ oldP, newP, close }) {
    let toastContent = null;
    if (close) {
      setOpenChangePasswordDialog(false);
    } else {
      const response = await changePassword({
        oldP: encryptPassword(oldP),
        newP: encryptPassword(newP),
        isAutoChange: mustChangePass,
      });
      if (response) {
        const { ok, errorMsg: msg } = response;
        if (!ok) {
          toastContent = msg;
        } else {
          setOpenChangePasswordDialog(false);
          if (mustChangePass) {
            toggleDropDown(false);
          }
        }
      } else {
        toastContent = t("ERROR_RESOURCE_NOT_FOUND_TEXT");
      }
    }

    if (toastContent !== null) {
      toast.error(toastContent, {
        containerId: TOAST_CONTAINER_LAYOUT,
      });
    }
  }

  return (
    <div className={classes.dropDown} ref={dropdownRef}>
      {openLeaveWorkDialog && openLeaveWorkDialog?.open && (
        <LeaveWorkConfirmationDialog
          open={openLeaveWorkDialog?.open}
          handleConf={handleOpenLeaveWorkConfDialogDecision}
        />
      )}

      {openLeaveWorkDialogClient && openLeaveWorkDialogClient?.open && (
        <LeaveWorkConfirmationDialog
          open={openLeaveWorkDialogClient?.open}
          handleConf={handleOpenLeaveWorkDialogClientDecision}
        />
      )}

      {openChangePasswordDialog && (
        <ChangePassDialog
          open={openChangePasswordDialog}
          handleChangePass={handleChangePass}
        />
      )}

      <CSSTransition
        in={activeMenu === "main"}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
      >
        <div className="menu">
          {companies && companies.length > 1 && (
            <Button
              className={classes.itemDiv}
              onClick={() => setActiveMenu("companies")}
            >
              <div className={classes.iconAndText}>
                <Icon
                  className="fas fa-laptop-house"
                  style={{ ...itemIconStart, marginRight: 5 }}
                />
                <span className={classes.itemLabel}>
                  {t("CLIENT_COMBO_LABEL")}
                </span>
              </div>
              <Icon
                className="fas fa-angle-right"
                style={{ ...itemIconEnd, color: muiTheme.palette.text.primary }}
              />
            </Button>
          )}

          <Button
            className={classes.itemDiv}
            onClick={() => setActiveMenu("langs")}
          >
            <div className={classes.iconAndText}>
              <Icon className="fas fa-flag" style={itemIconStart} />
              <span className={classes.itemLabel}>
                {t("DD_SETTINGS_LANGS")}
              </span>
            </div>
            <Icon
              className="fas fa-angle-right"
              style={{ ...itemIconEnd, color: muiTheme.palette.text.primary }}
            />
          </Button>

          <div className={classes.itemDiv}>
            <div className={classes.iconAndText}>
              <Icon
                className="fas fa-moon"
                style={{
                  ...itemIconStart,
                  color: muiTheme.palette.text.primary,
                }}
              />
              <span className={classes.itemLabel}>
                {t("DD_SETTINGS_DARK_THEME")}
              </span>
            </div>
            <Switch onChange={handleToogleTheme} checked={!isLightMode} />
          </div>

          {/* Change Password Toggler */}
          {!isLoginMad && (
            <Button
              className={classes.itemButton}
              onClick={() => {
                setOpenChangePasswordDialog(true);
              }}
            >
              <Icon
                className="fas fa-key"
                style={{
                  ...itemIconStart,
                  color: muiTheme.palette.text.primary,
                }}
              />
              <span className={classes.itemLabel}>
                {t("CHANGE_PASS_DIALOG_TITLE")}
              </span>
            </Button>
          )}

          <Button
            className={classes.itemButton}
            onClick={() =>
              handleNotificationsDock({ open: true, isFromConfig: true })
            }
          >
            {newVersion && newVersion !== "" ? (
              <Badge badgeContent={1} classes={{ badge: classes.customBadge }}>
                <Icon className="fas fa-bell" />
              </Badge>
            ) : (
              <Icon
                className="fas fa-bell"
                style={{
                  ...itemIconStart,
                  color: muiTheme.palette.text.primary,
                }}
              />
            )}

            <span className={classes.itemLabel}>
              {t("NOTIFICATIONS_TITLE")}
            </span>
          </Button>

          <Hidden smUp>
            <Button
              className={classes.itemButton}
              onClick={() => {
                setOpenLogoutConfirmation(true);
              }}
            >
              <Icon
                className="fas fa-sign-out-alt"
                style={{
                  ...itemIconStart,
                  color: muiTheme.palette.text.primary,
                }}
              />
              <span className={classes.itemLabel}>{t("TOOLTIP_LOGOUT")}</span>
            </Button>
          </Hidden>
        </div>
      </CSSTransition>

      {/* LANGUAGES */}
      <CSSTransition
        in={activeMenu === "langs"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
      >
        <div className={classes.menu}>
          <Button
            className={classes.itemButton}
            onClick={() => setActiveMenu("main")}
          >
            <Icon
              className="fas fa-angle-left"
              style={{ ...itemIconStart, color: muiTheme.palette.text.primary }}
            />
            <h3 className={classes.itemLabel}>{t("DD_SETTINGS_LANGS")}</h3>
          </Button>

          {languages
            .filter(
              (lang) =>
                lang?.enabled ||
                (!isNil(lang?.sub) && !isEmpty(lang?.sub) && lang?.enabled)
            )
            .map((lang) => {
              const haveSub =
                !isNil(lang?.sub) && isArray(lang.sub) && !isEmpty(lang?.sub);
              if (haveSub) {
                const isDefaultExpanded = some(lang?.sub, { value: locale });
                return (
                  <div key={lang.value} className={classes.rootAccordion}>
                    <Accordion
                      className={classes.accodtion}
                      elevation={0}
                      defaultExpanded={isDefaultExpanded}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${lang.value}-content`}
                        id={`${lang.value}-header`}
                      >
                        <div className={classes.iconAndText}>
                          <Typography
                            key={lang.value}
                            variant="h5"
                            style={{ ...flagIConStart, fontWeight: "bold" }}
                          >
                            {lang.letters}
                          </Typography>
                          {lang.label}
                          {isDefaultExpanded && (
                            <Icon
                              className="fas fa-dot-circle"
                              style={{
                                ...commonIcon,
                                marginLeft: 8,
                                fontSize: 12,
                              }}
                            />
                          )}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionDetails}>
                        {lang.sub.map((sub) => {
                          return (
                            <div
                              key={sub.value}
                              className={classes.itemDivLang}
                            >
                              <div
                                className={classes.iconAndText}
                                style={{ marginLeft: 20 }}
                              >
                                <ReactCountryFlag
                                  svg
                                  countryCode={sub?.flag}
                                  style={flagIConStart}
                                />
                                {sub.label}
                              </div>
                              <Radio
                                key={`radio_${sub.value}`}
                                checked={locale === sub.value}
                                onChange={() =>
                                  handleOpenLeaveWorkDialog(sub.value)
                                }
                                name={`radio_${sub.value}`}
                                inputProps={{ "aria-label": "A" }}
                              />
                            </div>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              } else {
                return (
                  <div key={lang.value} className={classes.itemDivLang}>
                    <div className={classes.iconAndText}>
                      <ReactCountryFlag
                        svg
                        countryCode={lang?.flag}
                        style={flagIConStart}
                      />
                      {lang.label}
                    </div>
                    <Radio
                      key={`radio_${lang.value}`}
                      checked={locale === lang.value}
                      onChange={() => handleOpenLeaveWorkDialog(lang.value)}
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "A" }}
                    />
                  </div>
                );
              }
            })}
        </div>
      </CSSTransition>

      {/* COMPANIES */}
      <CSSTransition
        in={activeMenu === "companies"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
      >
        <div className="menu">
          <Button
            className={classes.itemButton}
            onClick={() => setActiveMenu("main")}
          >
            <Icon
              className="fas fa-angle-left"
              style={{ ...itemIconStart, color: muiTheme.palette.text.primary }}
            />
            <h3 className={classes.itemLabel}>{t("CLIENT_COMBO_LABEL")}</h3>
          </Button>

          {companies.map((comp) => {
            return (
              <div key={comp.codCompany} className={classes.itemDiv}>
                <div className={classes.iconAndText}>{comp.name}</div>
                <Radio
                  key={`radio_${comp.codCompany}`}
                  checked={
                    auth &&
                    auth.company &&
                    auth.company.codCompany &&
                    auth.company.codCompany === comp.codCompany
                  }
                  onChange={() => {
                    handleOpenLeaveWorkDialogClient({
                      target: {
                        value: comp.codCompany,
                      },
                    });
                  }}
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
            );
          })}
        </div>
      </CSSTransition>
    </div>
  );
}

export default DropDownSettings;
