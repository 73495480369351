import { includes, isEmpty, isNil } from "lodash";

export async function downloadFile(url, name) {
  var link = document.createElement("a");
  link.download = name;
  link.href = url;
  link.target = "_self";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function getFileExtenson(fileName, withDot) {
  if (isNil(fileName) || isEmpty(fileName) || !includes(fileName, ".")) {
    return fileName;
  }

  const pos = fileName.lastIndexOf(".");
  if (withDot) {
    return fileName.substring(pos).toUpperCase();
  } else {
    return fileName.substring(pos + 1).toUpperCase();
  }
}

export function getFileNameWithouExtension(fileName) {
  if (isNil(fileName) || isEmpty(fileName) || !includes(fileName, ".")) {
    return fileName;
  }

  const pos = fileName.lastIndexOf(".");
  return fileName.substring(0, pos);
}

export function getNewFileNameAvoidDuplicates(fileName, filesArray) {
  const regex = /^(.*?)(?:\s-\s\((\d+)\))?(\.[^.]+)$/i;

  let suffix = 0;
  let newFileName = fileName;
  let match;

  do {
    let isDuplicate = false;
    for (const currentFile of filesArray) {
      if (currentFile?.name === newFileName) {
        isDuplicate = true;
        break;
      }
    }
    if (!isDuplicate) {
      break;
    }

    suffix++;
    match = newFileName.match(regex);
    if (match) {
      newFileName = `${match[1]} - (${suffix})${match[3]}`;
    } else {
      newFileName = `${newFileName} - (${suffix})`;
    }
  } while (true);

  return newFileName;
}

export function renameFilesToAvoidDuplicates(fileList, currentFiles) {
  const result = [];
  for (let i = 0; i < fileList.length; i++) {
    const file = fileList[i];
    const fileName = file.name;
    const newFileName = getNewFileNameAvoidDuplicates(
      fileName,
      getFileNames(currentFiles)
    );

    // create a new File object with the modified name and replace the old one in the FileList
    const fileToAdd = new File([file], newFileName, { type: file.type });
    result.push(fileToAdd);
  }

  return result;
}

function getFileNames(fileList) {
  const fileNames = [];
  for (let i = 0; i < fileList.length; i++) {
    fileNames.push({ name: fileList[i].name });
  }
  return fileNames;
}
