import { useEffect, useMemo } from "react";
import { useTheme } from "@material-ui/core";
import {
  filter,
  get,
  has,
  includes,
  isBoolean,
  isEmpty,
  isEqual,
  isNil,
  isString,
  orderBy,
  some,
  toUpper,
  toString,
  find,
  isArray,
  isNumber,
  isFunction,
  findIndex,
  uniqBy,
} from "lodash";
import { resolveFieldAgainstShadowStatus } from "../../util/UtilForm";
import useSafeEffect from "./useSafeEffect";
import useWizardStore from "../stores/WizardStore";
import { FORM_OPERATION_NEW } from "../../util/Constants";

export const NOT_IN_WIZARD = "NOT_IN_WIZARD";

function validateValueToBeEnabled(value) {
  const enabled =
    isEqual("S", toUpper(toString(value))) ||
    isEqual("TRUE", toUpper(toString(value))) ||
    isEqual("Y", toUpper(toString(value)));

  return enabled;
}

function cascadeValueToBeEnabledInShadowStatus(value, shadowStatus) {
  if (isNil(value)) {
    return false;
  } else if (!isNil(value) && isString(value)) {
    const valueInShadowStatus = resolveFieldAgainstShadowStatus({
      field: value,
      shadowStatus,
      completeIdControl: value,
    });

    return validateValueToBeEnabled(valueInShadowStatus);
  } else if (!isNil(value) && isBoolean(value)) {
    return value === true;
  } else {
    return false;
  }
}

function foundSectionUsingBlockOfStepsBlocks({ stepBlocks, formSchema }) {
  if (
    isNil(stepBlocks) ||
    !isArray(stepBlocks) ||
    isEmpty(stepBlocks) ||
    isNil(formSchema) ||
    isNil(formSchema.sections) ||
    isEmpty(formSchema.sections)
  ) {
    return null;
  }

  let foundSection = null;
  const sections = formSchema.sections;

  for (const section of sections) {
    if (
      !isNil(section) &&
      !isNil(section.name) &&
      !isNil(section.blocks) &&
      !isEmpty(section.blocks)
    ) {
      const blocks = section.blocks;
      for (const block of blocks) {
        if (!isNil(block) && !isNil(block.id) && block.id === stepBlocks[0]) {
          foundSection = section?.name;
          break;
        }
      }

      if (!isNil(foundSection)) {
        break;
      }
    }
  }

  return foundSection;
}

export default function useWizard({
  wizardConfig,
  shadowStatus,
  blockId,
  formSchema,
  selectedTab,
  moveToAnotherSectionByIndex,
  formInstanceId,
  isListBlock,
  blockIsDisabledByForm,
  operation,
}) {
  const muiTheme = useTheme();

  const {
    setCompletedSteps,
    getCompletedStepsByFormInstanceId,
    setPrevJump,
    getPrevJump,
    addNewBadge,
    handleDisabledBlock,
  } = useWizardStore();

  const completedSteps = getCompletedStepsByFormInstanceId({ formInstanceId });
  const prevJump = getPrevJump({ formInstanceId });

  const isWizardEnabled = useMemo(() => {
    if (isNil(wizardConfig) || operation !== FORM_OPERATION_NEW) {
      return false;
    }

    if (!isNil(wizardConfig)) {
      const { wizardEnabled } = wizardConfig;

      return cascadeValueToBeEnabledInShadowStatus(wizardEnabled, shadowStatus);
    }

    return false;
  }, [wizardConfig, operation, shadowStatus]);

  // ------------ CURRENT ------------

  const currentStepColor = useMemo(() => {
    if (
      !isNil(muiTheme) &&
      !isNil(wizardConfig) &&
      !isNil(wizardConfig.wizardStyles) &&
      !isNil(wizardConfig.wizardStyles.wizardCurrentStepColor) &&
      has(
        wizardConfig.wizardStyles.wizardCurrentStepColor,
        muiTheme.palette.type
      )
    ) {
      return get(
        wizardConfig.wizardStyles.wizardCurrentStepColor,
        muiTheme.palette.type
      );
    }

    return muiTheme?.palette?.content?.mainColor;
  }, [muiTheme, wizardConfig]);

  const currentStep = useMemo(() => {
    if (
      !isNil(wizardConfig) &&
      !isNil(wizardConfig.wizardSteps) &&
      !isEmpty(wizardConfig.wizardSteps) &&
      !isNil(shadowStatus)
    ) {
      const steps = wizardConfig.wizardSteps;
      const orderedSteps = orderBy(steps, "wizardStep");
      if (!isNil(orderedSteps) && !isEmpty(orderedSteps)) {
        for (const os of orderedSteps) {
          if (!isNil(os)) {
            const {
              blockId,
              wizardStep,
              wizardStepEnabled,
              wizardStepCompletedFlag,
            } = os;

            const isStepWizardEnabledByFormValue =
              cascadeValueToBeEnabledInShadowStatus(
                wizardStepEnabled,
                shadowStatus
              );

            if (
              isNil(wizardStepEnabled) ||
              isStepWizardEnabledByFormValue === true
            ) {
              const isStepCompleted = cascadeValueToBeEnabledInShadowStatus(
                wizardStepCompletedFlag,
                shadowStatus
              );

              if (isStepCompleted === false) {
                setCompletedSteps({
                  formInstanceId,
                  blockId,
                  isStepCompleted,
                });
                return wizardStep;
              } else {
                setCompletedSteps({
                  formInstanceId,
                  blockId,
                  isStepCompleted,
                });
              }
            }
          }
        }
      }
    }

    return null;
  }, [wizardConfig, shadowStatus, setCompletedSteps, formInstanceId]);

  const currentStepBlocks = useMemo(() => {
    if (
      isWizardEnabled &&
      !isNil(currentStep) &&
      !isNil(wizardConfig) &&
      !isNil(wizardConfig.wizardSteps)
    ) {
      return filter(wizardConfig.wizardSteps, function (o) {
        return o?.wizardStep === currentStep;
      }).map((x) => {
        return x?.blockId;
      });
    } else {
      return [];
    }
  }, [currentStep, isWizardEnabled, wizardConfig]);

  const currentStepSection = useMemo(() => {
    return foundSectionUsingBlockOfStepsBlocks({
      stepBlocks: currentStepBlocks,
      formSchema,
    });
  }, [currentStepBlocks, formSchema]);

  // ------------ NEXT ------------

  const nextStep = useMemo(() => {
    if (
      !isNil(wizardConfig) &&
      !isNil(wizardConfig.wizardSteps) &&
      !isEmpty(wizardConfig.wizardSteps) &&
      !isNil(shadowStatus) &&
      !isNil(currentStep) &&
      isNumber(currentStep) &&
      isWizardEnabled
    ) {
      const steps = wizardConfig.wizardSteps;
      const orderedSteps = orderBy(steps, "wizardStep");
      if (!isNil(orderedSteps) && !isEmpty(orderedSteps)) {
        let foundNextStep = null;
        for (const os of orderedSteps) {
          if (!isNil(os)) {
            const { wizardStep, wizardStepEnabled } = os;

            const isStepWizardEnabledByFormValue = isNil(wizardStepEnabled)
              ? true
              : cascadeValueToBeEnabledInShadowStatus(
                  wizardStepEnabled,
                  shadowStatus
                );

            if (
              isStepWizardEnabledByFormValue === true &&
              wizardStep > currentStep
            ) {
              foundNextStep = wizardStep;
              break;
            }
          }
        }

        if (!isNil(foundNextStep)) {
          return foundNextStep;
        }
      }
    }

    return null;
  }, [currentStep, isWizardEnabled, shadowStatus, wizardConfig]);

  const nextStepBlocks = useMemo(() => {
    if (
      isWizardEnabled &&
      !isNil(currentStep) &&
      !isNil(wizardConfig) &&
      !isNil(wizardConfig.wizardSteps)
    ) {
      return filter(wizardConfig.wizardSteps, function (o) {
        return o?.wizardStep === nextStep;
      }).map((x) => {
        return x?.blockId;
      });
    } else {
      return [];
    }
  }, [currentStep, isWizardEnabled, nextStep, wizardConfig]);

  const nextStepSection = useMemo(() => {
    return foundSectionUsingBlockOfStepsBlocks({
      stepBlocks: nextStepBlocks,
      formSchema,
    });
  }, [nextStepBlocks, formSchema]);

  // ------------ PREV ------------

  const prevStep = useMemo(() => {
    if (
      !isNil(wizardConfig) &&
      !isNil(wizardConfig.wizardSteps) &&
      !isEmpty(wizardConfig.wizardSteps) &&
      !isNil(shadowStatus) &&
      !isNil(currentStep) &&
      isNumber(currentStep) &&
      isWizardEnabled
    ) {
      const steps = wizardConfig.wizardSteps;
      const orderedSteps = orderBy(steps, "wizardStep");
      if (!isNil(orderedSteps) && !isEmpty(orderedSteps)) {
        let foundPrevStep = null;
        for (const os of orderedSteps) {
          if (!isNil(os)) {
            const { wizardStep, wizardStepEnabled } = os;

            const isStepWizardEnabledByFormValue = isNil(wizardStepEnabled)
              ? true
              : cascadeValueToBeEnabledInShadowStatus(
                  wizardStepEnabled,
                  shadowStatus
                );

            if (
              isStepWizardEnabledByFormValue === true &&
              wizardStep < currentStep
            ) {
              foundPrevStep = wizardStep;
            }

            if (wizardStep >= currentStep) {
              break;
            }
          }
        }

        if (!isNil(foundPrevStep)) {
          return foundPrevStep;
        }
      }
    }

    return null;
  }, [currentStep, isWizardEnabled, shadowStatus, wizardConfig]);

  const prevStepBlocks = useMemo(() => {
    if (
      isWizardEnabled &&
      !isNil(currentStep) &&
      !isNil(wizardConfig) &&
      !isNil(wizardConfig.wizardSteps)
    ) {
      return filter(wizardConfig.wizardSteps, function (o) {
        return o?.wizardStep === prevStep;
      }).map((x) => {
        return x?.blockId;
      });
    } else {
      return [];
    }
  }, [currentStep, isWizardEnabled, prevStep, wizardConfig]);

  // ------------ FLAGS ------------

  const blockIsIncludedInWizardSteps = useMemo(() => {
    if (
      isWizardEnabled === false ||
      isNil(blockId) ||
      isNil(wizardConfig) ||
      isNil(wizardConfig?.wizardSteps)
    ) {
      return false;
    } else {
      return some(wizardConfig?.wizardSteps, { blockId });
    }
  }, [blockId, wizardConfig, isWizardEnabled]);

  const blockWizardStepIsEnabled = useMemo(() => {
    if (
      isWizardEnabled === false ||
      blockIsIncludedInWizardSteps === false ||
      isNil(wizardConfig) ||
      isNil(shadowStatus) ||
      isNil(wizardConfig.wizardSteps) ||
      isEmpty(wizardConfig.wizardSteps) ||
      some(wizardConfig.wizardSteps, { blockId }) === false
    ) {
      return false;
    }

    const wizardStepEnabled = find(wizardConfig.wizardSteps, {
      blockId,
    })?.wizardStepEnabled;

    if (!isNil(wizardStepEnabled)) {
      const isStepWizardEnabledByFormValue =
        cascadeValueToBeEnabledInShadowStatus(wizardStepEnabled, shadowStatus);

      return isStepWizardEnabledByFormValue === true;
    }

    return true;
  }, [
    blockId,
    blockIsIncludedInWizardSteps,
    isWizardEnabled,
    shadowStatus,
    wizardConfig,
  ]);

  const blockShouldBeFocused =
    !isNil(currentStepBlocks) &&
    !isEmpty(currentStepBlocks) &&
    includes(currentStepBlocks, blockId) &&
    blockWizardStepIsEnabled === true;

  const blockShouldBeDisabled = useMemo(() => {
    if (
      isWizardEnabled === false ||
      blockIsIncludedInWizardSteps === false ||
      blockWizardStepIsEnabled === false
    ) {
      return false;
    }

    return (
      (isNil(isListBlock) || isListBlock === false) &&
      (blockShouldBeFocused === false || blockWizardStepIsEnabled === false)
    );
  }, [
    isWizardEnabled,
    blockIsIncludedInWizardSteps,
    blockWizardStepIsEnabled,
    blockShouldBeFocused,
    isListBlock,
  ]);

  const blockWasCompletedBefore =
    !isNil(completedSteps) &&
    !isEmpty(completedSteps) &&
    includes(completedSteps, blockId);

  const blockWizardStep = some(wizardConfig?.wizardSteps, { blockId })
    ? find(wizardConfig?.wizardSteps, { blockId })?.wizardStep
    : null;

  const isBlockDisabledByProperty =
    blockIsIncludedInWizardSteps === true && blockWizardStepIsEnabled === false;

  const listBlocksInWizard = useMemo(() => {
    if (
      isWizardEnabled === true &&
      !isNil(wizardConfig) &&
      !isNil(wizardConfig?.wizardSteps) &&
      !isNil(formSchema) &&
      !isNil(formSchema.sections)
    ) {
      let innerArray = [];
      for (const step of wizardConfig?.wizardSteps) {
        if (!isNil(step)) {
          const { blockId } = step;
          if (!isNil(blockId)) {
            const sectionsToIterate = get(formSchema, "sections");
            for (const section of sectionsToIterate) {
              if (!isNil(section) && !isNil(section.blocks)) {
                for (const block of section.blocks) {
                  const isBlockDefinedInWizard = some(
                    wizardConfig?.wizardSteps,
                    {
                      blockId: block.id,
                    }
                  );

                  if (
                    !isNil(block) &&
                    !isNil(block.id) &&
                    toString(toUpper(block.blockType)) === "LIST" &&
                    isBlockDefinedInWizard === true
                  ) {
                    innerArray.push(block.id);
                    break;
                  }
                }
              }
            }
          }
        }
      }
      return uniqBy(innerArray);
    }

    return [];
  }, [formSchema, isWizardEnabled, wizardConfig]);

  const blockWasActiveBefore = useMemo(() => {
    if (blockWizardStepIsEnabled === false) {
      return false;
    }

    if (isNil(currentStep)) {
      return false;
    }

    return blockWizardStep <= currentStep;
  }, [blockWizardStep, blockWizardStepIsEnabled, currentStep]);

  const blockListShouldBeBordered =
    (!isNil(blockId) &&
      !isNil(listBlocksInWizard) &&
      !isEmpty(listBlocksInWizard) &&
      includes(listBlocksInWizard, blockId) &&
      blockWasCompletedBefore === true) ||
    blockWasActiveBefore === true;

  const blockHasAtLeastSomethingEnabledAndVisible = useMemo(() => {
    if (
      blockIsDisabledByForm === true ||
      !isNil(blockWizardStep) ||
      isNil(blockId) ||
      isNil(formSchema) ||
      isNil(formSchema.sections)
    ) {
      return false;
    }

    const section = find(formSchema.sections, (obj) =>
      find(obj?.blocks, { id: blockId })
    );

    if (!isNil(section) && !isEmpty(section)) {
      const block = find(section?.blocks, { id: blockId });
      if (!isNil(block)) {
        const blockType = block.blockType;
        if (toString(toUpper(blockType)) === "LIST") {
          return some(block?.columns, function (o) {
            return (
              !isNil(o) &&
              (isNil(o.hidden) || o.hidden === false) &&
              (isNil(o.canNew) || o.canNew === true)
            );
          });
        } else {
          return some(block?.schema?.properties, function (o) {
            return (
              !isNil(o) &&
              (isNil(o.hidden) || o.hidden === false) &&
              (isNil(o.canNew) || o.canNew === true)
            );
          });
        }
      }
    }

    return false;
  }, [blockIsDisabledByForm, blockWizardStep, blockId, formSchema]);

  // ------------ EFFECTS ------------

  useSafeEffect(() => {
    if (
      !isNil(currentStepSection) &&
      !isNil(selectedTab) &&
      !isNil(formSchema) &&
      !isNil(formSchema?.sections) &&
      isArray(formSchema?.sections) &&
      !isNil(formSchema?.sections?.[selectedTab]) &&
      !isNil(formSchema.sections?.[selectedTab]?.name) &&
      formSchema.sections?.[selectedTab]?.name !== currentStepSection &&
      !isNil(moveToAnotherSectionByIndex) &&
      isFunction(moveToAnotherSectionByIndex) &&
      currentStepSection !== prevJump &&
      includes(prevStepBlocks, blockId)
    ) {
      const whereToJump = findIndex(formSchema.sections, {
        name: currentStepSection,
      });

      if (
        !isNil(whereToJump) &&
        isNumber(whereToJump) &&
        whereToJump >= 0 &&
        whereToJump !== prevJump &&
        !isNil(completedSteps) &&
        !isEmpty(completedSteps)
      ) {
        if (isNil(isListBlock) || isListBlock === false) {
          setPrevJump({ formInstanceId, jump: whereToJump });
          setTimeout(() => {
            moveToAnotherSectionByIndex(whereToJump);
          }, 50);
        } else {
          setPrevJump({ formInstanceId, jump: whereToJump });
          addNewBadge({ formInstanceId, sectionName: currentStepSection });
        }
      }
    }

    return () => null;
  }, [
    currentStepSection,
    selectedTab,
    formSchema,
    moveToAnotherSectionByIndex,
    prevJump,
    completedSteps,
    isListBlock,
    blockId,
    prevStepBlocks,
    setPrevJump,
    formInstanceId,
    addNewBadge,
  ]);

  useEffect(() => {
    if (
      !isNil(formInstanceId) &&
      isNil(prevJump) &&
      !isNil(currentStepSection)
    ) {
      setPrevJump({ formInstanceId, jump: currentStepSection });
    }
  }, [formInstanceId, prevJump, currentStepSection, setPrevJump]);

  useEffect(() => {
    if (!isNil(handleDisabledBlock) && isFunction(handleDisabledBlock)) {
      handleDisabledBlock({
        formInstanceId,
        blockId,
        isDisabled:
          isBlockDisabledByProperty === true || blockShouldBeDisabled === true,
      });
    }
  }, [
    formInstanceId,
    blockId,
    isBlockDisabledByProperty,
    blockShouldBeDisabled,
    handleDisabledBlock,
  ]);

  return {
    currentStepColor,
    isWizardEnabled,
    currentStep,
    currentStepBlocks,
    blockShouldBeFocused,
    blockShouldBeDisabled,
    blockWasCompletedBefore,
    blockWizardStep,
    isBlockDisabledByProperty,
    currentStepSection,
    nextStep,
    nextStepSection,
    blockListShouldBeBordered,
    listBlocksInWizard,
    blockHasAtLeastSomethingEnabledAndVisible,
  };
}
