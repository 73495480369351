import React, { createContext, useState, useEffect } from "react";
import { ThemeProvider } from "@material-ui/core";

import { LIGHT_MODE, DARK_MODE, THEME_PROP } from "../../util/Constants";
import { lightTheme, darkTheme } from "../../shared/theme/Theme";

//Creating the context
const ThemeContext = createContext();

const ThemeContextProvider = ({ children }) => {
  const [theme, setTheme] = useState(LIGHT_MODE);

  //Define toggle theme function
  const toggleTheme = () => {
    if (theme === LIGHT_MODE) {
      window.localStorage.setItem(THEME_PROP, DARK_MODE);
      setTheme(DARK_MODE);
    } else {
      window.localStorage.setItem(THEME_PROP, LIGHT_MODE);
      setTheme(LIGHT_MODE);
    }
  };

  //Call use effect hook to store in browser localstorage the actual mode
  useEffect(() => {
    const localTheme = window.localStorage.getItem(THEME_PROP);
    setTheme(localTheme || LIGHT_MODE);
  }, []);

  //Defining theme mode
  let isLightMode = theme === LIGHT_MODE ? true : false;
  let themeMode = isLightMode ? lightTheme : darkTheme;

  return (
    <ThemeContext.Provider
      value={{ theme, toggleTheme, isLightMode, setTheme }}
    >
      <ThemeProvider theme={themeMode}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

//Better exporting all things at bottom when we have more tan one
export { ThemeContext, ThemeContextProvider };
