import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Chip from "@material-ui/core/Chip";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Grow,
  Icon,
  IconButton,
  Typography,
  Tooltip,
  Divider,
} from "@material-ui/core";

const commonIcons = {
  width: "auto",
  height: "auto",
  padding: 1,
};

const MAP_STATUS_COLORS = {
  PENDIENTE: "#F700FF",
  CURSO: "#FEFF00",
  FINALIZADO: "#00FF0D",
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "15px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  refreshContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    marginBottom: 10,
  },
  listLogs: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    maxHeight: "90%",
  },
  chipRoot: {
    marginRight: 10,
  },
  chipRootStatus: {
    minWidth: 112,
    display: "flex",
    justifyContent: "flex-start",
  },
  chipRootRoute: {
    marginRight: 10,
    minWidth: 250,
  },
  chipFinish: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: `${MAP_STATUS_COLORS["FINALIZADO"]}55`,
    marginLeft: 10,
  },
  chipDoing: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: `${MAP_STATUS_COLORS["CURSO"]}55`,
    marginLeft: 10,
  },
  chipPending: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: `${MAP_STATUS_COLORS["PENDIENTE"]}55`,
    marginLeft: 10,
  },
  chipDelete: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: `${theme.palette.content.mainColor}55`,
    marginLeft: 10,
  },
  summaryDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  statusDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paragraphMod: {
    fontWeight: "bold",
  },
  accDetails: {
    flexDirection: "column",
  },
}));

export default function AccordionLogs({
  logs,
  refreshData,
  handleUpdate,
  handleDelete,
}) {
  const classes = useStyles();
  const [idUpdating, setIdUpdating] = useState(null);

  if (logs == null || logs.length === 0) {
    return null;
  }

  function handleClick(e, id) {
    e.stopPropagation();
    if (idUpdating == null) {
      setIdUpdating(id);
    } else {
      setIdUpdating(null);
    }
  }

  async function handleUpdateClick(e, log, status) {
    //Call service to update log error
    e.stopPropagation();
    setIdUpdating(null);
    await handleUpdate({
      ...log,
      status,
    });
    refreshData();
  }

  async function handleDeleteClick(e, log) {
    //Call service to update log error
    e.stopPropagation();
    await handleDelete(log);
    refreshData();
  }

  return (
    <div className={classes.root}>
      <div className={classes.refreshContainer}>
        <IconButton onClick={refreshData}>
          <Icon className="fas fa-sync" style={commonIcons} />
        </IconButton>
      </div>
      <div className={classes.listLogs}>
        {logs.map((l) => {
          const {
            id,
            route,
            clientTime,
            trace,
            status,
            user,
            codCompany,
            userMod,
          } = l;
          return (
            <Accordion style={{ marginBottom: 10 }} key={id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={classes.summaryDiv}>
                  <div className={classes.statusDiv}>
                    <Chip
                      classes={{ root: classes.chipRootStatus }}
                      icon={
                        <Icon
                          className="fas fa-circle-notch"
                          style={{
                            ...commonIcons,
                            color: MAP_STATUS_COLORS[status],
                          }}
                        />
                      }
                      onClick={(e) => handleClick(e, id)}
                      label={status}
                    />
                    {idUpdating &&
                      idUpdating === id &&
                      status !== "FINALIZADO" && (
                        <Grow in={true} timeout={200}>
                          <Tooltip title="Marcar finalizado">
                            <IconButton
                              classes={{ root: classes.chipFinish }}
                              onClick={(e) =>
                                handleUpdateClick(e, l, "FINALIZADO")
                              }
                            >
                              <Icon
                                className="fas fa-check-circle"
                                style={{ ...commonIcons, fontSize: "1rem" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grow>
                      )}
                    {idUpdating && idUpdating === id && status !== "CURSO" && (
                      <Grow in={true} timeout={400}>
                        <Tooltip title="Marcar en curso">
                          <IconButton
                            classes={{ root: classes.chipDoing }}
                            onClick={(e) => handleUpdateClick(e, l, "CURSO")}
                          >
                            <Icon
                              className="fas fa-hammer"
                              style={{ ...commonIcons, fontSize: "1rem" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grow>
                    )}
                    {idUpdating && idUpdating === id && status !== "PENDIENTE" && (
                      <Grow in={true} timeout={400}>
                        <Tooltip title="Marcar pendiente">
                          <IconButton
                            classes={{ root: classes.chipPending }}
                            onClick={(e) =>
                              handleUpdateClick(e, l, "PENDIENTE")
                            }
                          >
                            <Icon
                              className="fas fa-ban"
                              style={{ ...commonIcons, fontSize: "1rem" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grow>
                    )}

                    {idUpdating && idUpdating === id && (
                      <Grow in={true} timeout={400}>
                        <>
                          <Divider
                            orientation="vertical"
                            flexItem
                            style={{ marginLeft: 10 }}
                          />
                          <Tooltip title="Eliminar registro">
                            <IconButton
                              classes={{ root: classes.chipDelete }}
                              onClick={(e) => handleDeleteClick(e, l)}
                            >
                              <Icon className="fas fa-trash-alt" />
                            </IconButton>
                          </Tooltip>
                        </>
                      </Grow>
                    )}
                  </div>
                  <div>
                    <Chip
                      classes={{ root: classes.chipRoot }}
                      icon={
                        <Icon
                          className="fas fa-fingerprint"
                          style={{ ...commonIcons }}
                        />
                      }
                      label={id}
                    />
                    <Chip
                      classes={{ root: classes.chipRoot }}
                      icon={
                        <Icon
                          className="fas fa-user"
                          style={{ ...commonIcons }}
                        />
                      }
                      label={user}
                    />
                    <Chip
                      classes={{ root: classes.chipRoot }}
                      icon={
                        <Icon
                          className="fas fa-building"
                          style={{ ...commonIcons }}
                        />
                      }
                      label={codCompany}
                    />
                    <Chip
                      classes={{ root: classes.chipRoot }}
                      icon={
                        <Icon
                          className="fas fa-clock"
                          style={{ ...commonIcons }}
                        />
                      }
                      label={clientTime}
                    />
                    <Chip
                      classes={{ root: classes.chipRootRoute }}
                      icon={
                        <Icon
                          className="fas fa-route"
                          style={{ ...commonIcons }}
                        />
                      }
                      label={route}
                    />
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.accDetails}>
                {userMod && (
                  <p className={classes.paragraphMod}>
                    Last modification by: {userMod}{" "}
                  </p>
                )}
                <Typography>{trace}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
}
