import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Icon,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UndoIcon from "@material-ui/icons/Undo";
import SaveIcon from "@material-ui/icons/Save";

import { TOAST_NOTIFICATIONS_CONFIG_CONTAINER } from "../../../util/Constants";
import { useTranslation } from "react-i18next";
import { includes, isArray, isEmpty, isNil } from "lodash";
import { useNotificationStore } from "../../../core/stores/solutions/notification/NotificationStore";
import { useRequestHeaders } from "../../../core/hooks/useRequestHeaders";
import { AuthContext } from "../../../core/providers/AuthContext";
import GeneralDialog from "../components/GeneralDialog";
import UserShouldChangeTelephoneDialog from "../components/UserShouldChangeTelephoneDialog";
import ChangeTelephoneNumberDialog from "../components/changeTelephoneNumberDialog/ChangeTelephoneNumberDialog";
import GenericToastContainer from "../../../components/toast/GenericToastContainer";

const MAP_TYPES_ICONS = {
  MAIL: {
    icon: "fas fa-envelope",
    style: {
      width: "auto",
      height: "auto",
      padding: "1px",
      marginTop: "7px",
    },
  },
  WAPP: {
    icon: "fab fa-whatsapp",
    style: {
      width: "auto",
      height: "auto",
      padding: "1px",
      marginTop: "7px",
      transform: "scale(1.2)",
    },
  },
};

const TYPES_NEED_TELEPHONE_VALIDATED = ["WAPP"];

const titleIcon = {
  padding: 1,
  width: "auto",
  height: "auto",
};

const telephoneIcon = {
  ...titleIcon,
  marginRight: 5,
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: theme.spacing(1),
    overflow: "auto",
  },
  titleBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    borderBottom: "1px solid",
    marginBottom: 10,
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      flexDirection: "column",
    },
  },
  titleBoxItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "space-between",
    },
  },
  contentBox: {},
  titleTypo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "6px",
  },
  refreshBtn: {
    marginLeft: 5,
  },
  accordion: {
    marginBlock: 12,
  },
  accordionSummary: {
    minHeight: "24px",
    borderBottom: "1px solid lightgrey",
  },
  accordionDetails: {
    display: "flex",
    flexDirection: "column",
    padding: "8px 16px 8px 6%",
    gap: 10,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  btnUndo: {
    textTransform: "none",
    marginInline: theme.spacing(1),
    "&:hover": {
      transform: "scale(1.02)",
    },
    [theme.breakpoints.down("sm")]: {
      marginInline: 0,
    },
  },
  btnSave: {
    textTransform: "none",
    marginInline: theme.spacing(1),
    backgroundColor: `${theme.palette.content.mainColor}DD`,
    color: "#FFF",
    "&:hover": {
      transform: "scale(1.02)",
      backgroundColor: `${theme.palette.content.mainColor}DD`,
    },
  },
  formControlContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 5,
    position: "relative",
    borderBottom: "1px solid #d3d3d369",
  },
  typesContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    gap: "10px",
    // display: "flex",
    // position: "absolute",
    // top: "0px",
    // left: "0px",
    // right: "0px",
    // marginLeft: "50%",
    // gap: 10,
  },
  formControlType: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  checkTypeEnabled: {
    backgroundColor: "red",
  },
  checkTypeDisabled: {
    backgroundColor: "yellow",
  },
  changePhoneNumberBtn: {
    textTransform: "none",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 5,
    backgroundColor: `${theme.palette.content.mainColor}66`,
    "&:hover": {
      backgroundColor: `${theme.palette.content.mainColor}99`,
    },
  },
}));

export default function Notifications({ hideTitleIcon }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const REQUEST_HEADERS = useRequestHeaders();
  const { auth, logout } = useContext(AuthContext);
  const {
    isFetchingInitialConfig,
    fetchInitialConfig,
    currentConfig,
    isGeneralDisabled,
    setOpenGeneralDialog,
    changeThirdLevel,
    isEditing,
    undoChanges,
    solutionsDisabled,
    toggleSolution,
    saveUserNotificationConfigChanges,
    isSavingChanges,
    mustConfigTelephoneNumber,
  } = useNotificationStore();
  const [alertPhoneOpen, setAlertPhoneOpen] = useState(false);
  const [openChangePhoneDialog, setOpenChangePhoneDialog] = useState(false);
  const [openChangePhoneDialogDirectly, setOpenChangePhoneDialogDirectly] =
    useState(false);

  useEffect(() => {
    fetchInitialConfig({ t, REQUEST_HEADERS, logout });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  function handleChangeGeneralSwitch(event) {
    event.stopPropagation();
    event.preventDefault();
    setOpenGeneralDialog(true);
  }

  function handleCloseNoPhone(directly) {
    setAlertPhoneOpen(false);
    if (directly) {
      setOpenChangePhoneDialogDirectly(true);
      setOpenChangePhoneDialog(true);
    } else {
      setOpenChangePhoneDialog(true);
    }
  }

  const handleCloseChangePhone = useCallback(
    (changed) => {
      setOpenChangePhoneDialog(false);
      if (changed) {
        undoChanges();
        fetchInitialConfig({ t, REQUEST_HEADERS, logout });
      }
    },
    [REQUEST_HEADERS, fetchInitialConfig, logout, t, undoChanges]
  );

  return (
    <section className={classes.container}>
      <GenericToastContainer
        containerId={TOAST_NOTIFICATIONS_CONFIG_CONTAINER}
      />
      {alertPhoneOpen && (
        <UserShouldChangeTelephoneDialog
          open={alertPhoneOpen}
          handleClose={handleCloseNoPhone}
        />
      )}

      {openChangePhoneDialog && (
        <ChangeTelephoneNumberDialog
          open={openChangePhoneDialog}
          handleClose={handleCloseChangePhone}
          addDirectly={openChangePhoneDialogDirectly}
        />
      )}

      <Box className={classes.titleBox}>
        <Box className={classes.titleBoxItem}>
          <Typography className={classes.titleTypo}>
            {isNil(hideTitleIcon) ||
              (hideTitleIcon === false && (
                <Icon className="fas fa-bell" style={titleIcon} />
              ))}
            {t("NOTIFICATIONS_PANEL")}
          </Typography>
          {isEditing && (
            <Button
              variant="contained"
              className={classes.btnUndo}
              startIcon={<UndoIcon />}
              onClick={() => undoChanges()}
              disabled={isSavingChanges}
            >
              {t("UNDO")}
            </Button>
          )}

          {isEditing && (
            <Button
              variant="contained"
              className={classes.btnSave}
              startIcon={<SaveIcon />}
              disabled={isSavingChanges}
              onClick={() =>
                saveUserNotificationConfigChanges({
                  t,
                  REQUEST_HEADERS,
                  logout,
                })
              }
            >
              {isSavingChanges ? (
                <CircularProgress size={16} style={{ color: "#FFF" }} />
              ) : (
                <Typography>{t("FORM_SAVE")}</Typography>
              )}
            </Button>
          )}
        </Box>

        <Box className={classes.titleBoxItem}>
          <GeneralDialog />
          {!isFetchingInitialConfig && (
            <FormControlLabel
              control={
                <Switch
                  checked={!isGeneralDisabled}
                  name="general"
                  onChange={(event, checked) =>
                    handleChangeGeneralSwitch(event, checked)
                  }
                />
              }
              label={t("NOTIFICATIONS_ALLOW")}
            />
          )}

          <IconButton
            className={classes.refreshBtn}
            onClick={() => fetchInitialConfig({ t, REQUEST_HEADERS, logout })}
            disabled={isSavingChanges || isFetchingInitialConfig}
          >
            <Icon className="fas fa-sync" style={titleIcon} />
          </IconButton>
        </Box>
      </Box>
      {!isFetchingInitialConfig &&
        !isNil(currentConfig) &&
        !isEmpty(currentConfig) && (
          <Box className={classes.contentBox}>
            {/* Change Telephone Toggler */}
            <Button
              className={classes.changePhoneNumberBtn}
              onClick={() => {
                setOpenChangePhoneDialogDirectly(false);
                setOpenChangePhoneDialog(true);
              }}
            >
              <Icon className="fas fa-phone-square-alt" style={telephoneIcon} />
              <Typography>{t("CHANGE_TELEPHONE_TITLE")}</Typography>
            </Button>
            {Object.keys(currentConfig).map((nc) => {
              return (
                <Accordion
                  key={nc}
                  className={classes.accordion}
                  defaultExpanded
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                    className={classes.accordionSummary}
                  >
                    <FormControlLabel
                      aria-label="Acknowledge"
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={
                        <Switch
                          disabled={isGeneralDisabled}
                          checked={
                            !includes(solutionsDisabled, nc) &&
                            !isGeneralDisabled
                          }
                          onChange={(e, checked) => {
                            toggleSolution({
                              solution: nc,
                              newStatus: checked,
                            });
                          }}
                        />
                      }
                      label={t("NOTIFICATIONS_ALLOW", { label: nc })}
                    />
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    {!isNil(currentConfig?.[nc]) &&
                      isArray(currentConfig?.[nc]) &&
                      !isEmpty(currentConfig?.[nc]) && (
                        <>
                          {currentConfig?.[nc]?.map(
                            ({ key, name, possibleTypes }) => {
                              const isDisabledBySolution = includes(
                                solutionsDisabled,
                                nc
                              );

                              const hasAtLeastOneType =
                                !isNil(possibleTypes) &&
                                !isEmpty(possibleTypes) &&
                                isArray(possibleTypes);

                              return (
                                <div
                                  key={key}
                                  className={classes.formControlContainer}
                                >
                                  <label>{name}</label>
                                  {hasAtLeastOneType && (
                                    <div className={classes.typesContainer}>
                                      {possibleTypes.map(
                                        ({ type, enabled }) => {
                                          const disabledBecauseUserNeedToSetTelephone =
                                            mustConfigTelephoneNumber ===
                                              true &&
                                            TYPES_NEED_TELEPHONE_VALIDATED.includes(
                                              type
                                            );

                                          const isDisabledByOtherThings =
                                            isDisabledBySolution ||
                                            isGeneralDisabled;
                                          return (
                                            <FormControlLabel
                                              key={type}
                                              className={
                                                classes.formControlType
                                              }
                                              control={
                                                <Checkbox
                                                  name={key}
                                                  checked={
                                                    isDisabledByOtherThings ||
                                                    disabledBecauseUserNeedToSetTelephone
                                                      ? false
                                                      : enabled
                                                  }
                                                  disabled={
                                                    isDisabledByOtherThings
                                                  }
                                                  onChange={(e, checked) => {
                                                    if (
                                                      disabledBecauseUserNeedToSetTelephone
                                                    ) {
                                                      setAlertPhoneOpen(true);
                                                    } else {
                                                      changeThirdLevel({
                                                        solution: nc,
                                                        key,
                                                        newState: checked,
                                                        type,
                                                      });
                                                    }
                                                  }}
                                                  color="primary"
                                                />
                                              }
                                              label={
                                                <Icon
                                                  className={
                                                    MAP_TYPES_ICONS[type]?.icon
                                                  }
                                                  style={
                                                    MAP_TYPES_ICONS[type]?.style
                                                  }
                                                />
                                              }
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </>
                      )}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        )}
    </section>
  );
}
