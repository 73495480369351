import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { LOCALE_PROP } from "./util/Constants";
import Backend from "i18next-xhr-backend";

i18n
  .use(Backend)
  // connect with React
  .use(initReactI18next)
  .init({
    debug: false,
    lng: window.localStorage.getItem(LOCALE_PROP),
    fallbackLng: "en",
    lowerCaseLng: true,
    whitelist: ["es", "en", "pt", "cn", "fr", "en-ca"], //whenever want to add new langs add here
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
