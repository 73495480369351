import React, { useContext } from "react";
import {
  Divider,
  Icon,
  makeStyles,
  Paper,
  Typography,
  Button,
  ClickAwayListener,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import UserImage from "./UserImage";
import { AuthContext } from "../../../../../core/providers/AuthContext";

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    width: 320,
    height: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    padding: 15,
    boxShadow: "rgb(0 0 0 / 20%) 5px 2px 10px",
    [theme.breakpoints.down("xs")]: {
      width: 250,
      height: 175,
      padding: 10,
    },
  },
  profileInfo: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    overflow: "auto",
  },
  profileInfoTexts: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: 10,
  },
  buttonLogoutRoot: {
    padding: 10,
    height: 30,
    backgroundColor: `${theme.palette.content.mainColor}`,
    "&:hover": {
      backgroundColor: `${theme.palette.content.mainColor}`,
      transform: "scale(1.05)",
    },
  },
  buttonLogoutLabel: {
    textTransform: "none",
    gap: 5,
  },
  logoutTypo: {
    color: "white",
  },
  profileUserNameTypo: {
    fontSize: "0.75rem",
    fontWeight: "700",
    lineHeight: "1.6",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.65rem",
    },
  },
}));

export default function ProfilePopoverContent({
  setOpenLogoutConfirmation,
  setOpenProfilePop,
}) {
  const classes = useStyles();
  const { auth } = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <ClickAwayListener onClickAway={() => setOpenProfilePop(false)}>
      <Paper className={classes.paperContainer}>
        <div className={classes.profileInfo}>
          <UserImage isProfile={true} />
          <div className={classes.profileInfoTexts}>
            <Typography className={classes.profileUserNameTypo}>
              {auth?.userName}
            </Typography>
            <Typography variant="subtitle1">{auth?.company?.name}</Typography>
          </div>
        </div>
        <Divider />
        <Button
          classes={{
            root: classes.buttonLogoutRoot,
            label: classes.buttonLogoutLabel,
          }}
          onClick={() => {
            setOpenLogoutConfirmation(true);
          }}
        >
          <Icon className="fas fa-sign-out-alt" style={{ color: "white" }} />
          <Typography className={classes.logoutTypo}>
            {t("TOOLTIP_LOGOUT")}
          </Typography>
        </Button>
      </Paper>
    </ClickAwayListener>
  );
}
