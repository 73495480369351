import { useEffect, useState } from "react";
import { SERVER_HOST } from "../../util/Constants";
import useEnvironmentStore from "../stores/EnvironmentStore";

const INTERVAL_SECONDS =
  process.env.NODE_ENV === "development" ? 500000 * 1000 : 10 * 1000;

async function getFormHealth() {
  let time1 = performance.now();
  const response = await fetch(`${SERVER_HOST()}form/api/health`)
    .then((res) => {
      return !res?.ok;
    })
    .then((res) => {
      return res;
    })
    .catch((_) => {
      return true;
    });
  let time2 = performance.now();

  return {
    error: response,
    stat: time2 - time1,
  };
}

const rangesMs = {
  ok: "#7FE43C",
  medium: "#f1c40f",
  slow: "#ff7675",
};

function getStatusInfo(status, error, online) {
  if (!online) {
    return { color: "darkgrey", msg: "(No Internet)" };
  }

  if (error) {
    return { color: "darkgrey", msg: "(Server Down)" };
  }

  if (!status) {
    return { color: "darkgrey", msg: "" };
  }

  return status < 300
    ? { color: rangesMs.ok, msg: " ms (Good Connection)" }
    : status >= 300 && status <= 600
    ? { color: rangesMs.medium, msg: " ms (Acceptable Connection)" }
    : { color: rangesMs.slow, msg: " ms (Bad Connection)" };
}

const useStatusBadge = () => {
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);

  const selectedEnv = useEnvironmentStore((state) => state.selectedEnv);

  useEffect(() => {
    async function fetchApiHelth() {
      const res = await getFormHealth();
      setStatus(res?.stat ? Math.round(res.stat) : "-");
      setError(res?.error);
    }

    const timer = setTimeout(fetchApiHelth, 1000);
    const interval = setInterval(fetchApiHelth, INTERVAL_SECONDS);

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, [selectedEnv]);

  return {
    status,
    error,
    getStatusInfo,
  };
};

export default useStatusBadge;
