import React, { useEffect, useState, useContext, useRef } from "react";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import { TextField, useTheme } from "@material-ui/core";
import _, { isEmpty, isNil, isString } from "lodash";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { useTranslation } from "react-i18next";

import { useCanChangeSaved } from "../../../../core/hooks/useCanChangeSaved";
import * as handlersAPI from "../../handlers/api";
import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";
import { AuthContext } from "../../../../core/providers/AuthContext";
import { KEY_ARROW_DOWN, KEY_ARROW_UP } from "../../../../util/UtilKeys";
import {
  getDisabledSelectionServices,
  makeValuesToSetValues,
  returnFocusToRef,
} from "../../../../util/UtilForm";
import { toast } from "react-toastify";
import { TOAST_CONTAINER_LAYOUT } from "../../../../util/Constants";
import SelectionAdornment from "../../customSimpleFields/SimpleInputField/SelectionAdornment";

const useStyles = makeStyles((theme) => ({
  listBox: {
    maxHeight: 200,
  },
  focused: {
    "&:focus": {
      backgroundColor: `${theme.palette.content.mainColor}22`,
    },
  },
}));

async function functionToExecute(params) {
  const response = await handlersAPI.callSelectionService(params);
  if (!_.isNil(response) && response?.dataResult) {
    try {
      const dataToAnalyze = JSON.parse(response?.dataResult);
      return !_.isNil(dataToAnalyze) && !_.isEmpty(dataToAnalyze)
        ? dataToAnalyze
        : [];
    } catch (e) {
      return [];
    }
  } else {
    return [];
  }
}

const customFilterOptions = createFilterOptions({
  stringify: (option) => {
    return Object.values(option).join(" ");
  },
});

export default function HelperCellInput(props) {
  const {
    formInstanceId,
    completeIdControl,
    calculatedInputProps,
    column,
    operation,
    isSavedEntity,
    inputEnabled,
    inputValue,
    inputRef,
    isSettingValueInServer,
    selectionServices,
    handleContextMenu,
    handleValueTyping,
    changeValueFormContextMenu,
    helperDef,
    handleLostInputFocus,
    handleKeyPress,
    line,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(inputValue || null);
  const [isTyping, setIsTyping] = useState(false);
  const loading = open && options.length === 0;
  const internalRef = useRef(null);

  const { auth } = useContext(AuthContext);

  const { disableDueChangeSavedValue } = useCanChangeSaved({
    schema: column,
    operation,
    isSavedEntity,
  });

  const REQUEST_HEADERS = useRequestHeaders();

  const {
    keyService,
    groupBy,
    colsToShow,
    colsToReturn,
    validate,
    validateMsg,
  } = helperDef;

  const disabledServices = getDisabledSelectionServices(column);

  const allSelectionServices =
    !_.isNil(selectionServices) && !_.isEmpty(selectionServices)
      ? [...selectionServices, ...disabledServices]
      : disabledServices;

  //Definition of selection service to execute
  const serviceToExecute =
    !_.isNil(allSelectionServices) && !_.isEmpty(allSelectionServices)
      ? allSelectionServices.find((x) => x?.key === keyService)
      : null;

  const toReturn = serviceToExecute?.toReturn;

  const varToReturn =
    !_.isNil(colsToReturn) && !_.isEmpty(colsToReturn)
      ? colsToReturn
      : toReturn;

  const mainAccessor =
    !_.isNil(varToReturn) &&
    !_.isEmpty(varToReturn) &&
    !_.isNil(completeIdControl) &&
    !_.isEmpty(completeIdControl) &&
    _.isString(completeIdControl)
      ? varToReturn.find(
          (x) =>
            x?.to ===
            completeIdControl.substring(
              0,
              completeIdControl.lastIndexOf(`-${line}`)
            )
        )
      : null;

  const autoCompleteDisabled = serviceToExecute === null || !mainAccessor;

  useEffect(() => {
    let active = true;

    if (!loading || autoCompleteDisabled) {
      return undefined;
    }

    (async () => {
      const response = await functionToExecute({
        serviceName: serviceToExecute?.serviceName,
        replacedParamsWithValues: serviceToExecute?.params,
        dataResultDef: serviceToExecute?.dataResultDef,
        company: auth?.company?.codCompany,
        key: serviceToExecute?.key,
        formInstanceId,
        line,
        REQUEST_HEADERS,
      });

      if (active) {
        setOptions(response);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line
  }, [loading, autoCompleteDisabled, serviceToExecute]);

  useEffect(() => {
    if (
      inputValue &&
      inputValue.length > 0 &&
      isTyping &&
      !autoCompleteDisabled
    ) {
      setOpen(true);
    } else {
      //console.log("use effect 1 false");
      setOpen(false);
    }
  }, [isTyping, autoCompleteDisabled, inputValue]);

  useEffect(() => {
    if (open && value & !isTyping) {
      //console.log("use effect 2 false");
      setOpen(false);
    }
  }, [open, value, isTyping]);

  useEffect(() => {
    const handler = (e) => {
      const { keyCode } = e;
      const idFieldTriggered = e?.srcElement?.id;
      if (
        (keyCode === KEY_ARROW_UP || keyCode === KEY_ARROW_DOWN) &&
        options &&
        options.length > 0 &&
        !open &&
        idFieldTriggered === completeIdControl
      ) {
        setOpen(true);
      }
    };
    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [options, open, completeIdControl]);

  useEffect(() => {
    const handlerDelete = (e) => {
      const { keyCode } = e;
      const idFieldTriggered = e?.srcElement?.id;
      if (
        (keyCode === 46 || keyCode === 8) &&
        options &&
        options.length > 0 &&
        !open &&
        idFieldTriggered === completeIdControl
      ) {
        setOpen(true);
        setIsTyping(true);
      }
    };
    window.addEventListener("keydown", handlerDelete);
    return () => {
      window.removeEventListener("keydown", handlerDelete);
    };
  }, [options, open, inputValue, completeIdControl]);

  useEffect(() => {
    if (inputValue === "" || (_.isNil(inputValue) && !_.isNil(value))) {
      setValue(null);
    }
  }, [inputValue, value]);

  //Input styles
  const inputStyles = {
    fontFamily: theme.palette.form.inputFontFamily,
    textTransform:
      !_.isNil(column) && !_.isNil(column.textTransform)
        ? column.textTransform
        : "none",
    color:
      calculatedInputProps &&
      calculatedInputProps.color &&
      calculatedInputProps.color,
    fontSize:
      calculatedInputProps &&
      calculatedInputProps.fontSize &&
      calculatedInputProps.fontSize,
    backgroundColor:
      calculatedInputProps &&
      calculatedInputProps.backgroundColor &&
      calculatedInputProps.backgroundColor,
    //: "inherit",
    fontWeight:
      calculatedInputProps &&
      calculatedInputProps.fontWeight &&
      calculatedInputProps.fontWeight,
    fontStyle:
      calculatedInputProps &&
      calculatedInputProps.fontStyle &&
      calculatedInputProps.fontStyle,
    width: "100%",
  };

  const handleBlurHelper = (e) => {
    const realValue = e?.target?.value;
    if (
      !isNil(realValue) &&
      realValue !== "" &&
      validate === true &&
      !isNil(realValue) &&
      realValue !== "" &&
      !isNil(options) &&
      !isEmpty(options) &&
      !isNil(mainAccessor?.from)
    ) {
      const { from } = mainAccessor;
      const isValidOption = options.find((o) =>
        !isNil(o) && !isNil(o[mainAccessor?.from])
          ? o[from] === realValue
          : null
      );
      if (isNil(isValidOption)) {
        const messageToShow =
          !isNil(validateMsg) && validateMsg !== "" && isString(validateMsg)
            ? validateMsg
            : t("HELPER_INVALID_OPTION");
        returnFocusToRef(internalRef);
        toast.error(messageToShow, {
          containerId: TOAST_CONTAINER_LAYOUT,
          toastId: completeIdControl,
        });
        return;
      }
    } else {
      setOpen(false);
      handleLostInputFocus(e);
      setIsTyping(false);
    }
  };

  return (
    <Autocomplete
      ref={inputRef}
      id={completeIdControl}
      open={open}
      fullWidth
      getOptionSelected={(option, value) => {
        return option[mainAccessor?.from] === value[mainAccessor?.from];
      }}
      getOptionLabel={(option) => {
        return !_.isNil(option[mainAccessor?.from])
          ? option[mainAccessor?.from]
          : option;
      }}
      groupBy={(option) => {
        if (!_.isNil(groupBy)) {
          return option?.[groupBy];
        }
        return null;
      }}
      options={options}
      clearOnBlur={false}
      disableClearable={true}
      freeSolo
      autoHighlight
      classes={{
        listbox: classes.listBox,
        inputFocused: classes.focused,
      }}
      value={value}
      onChange={(e, newValue) => {
        setValue(newValue);
        const valuesToCallService = makeValuesToSetValues(
          varToReturn,
          newValue,
          line
        );
        changeValueFormContextMenu(valuesToCallService);
        //console.log("onChange false");
        setOpen(false);
        setIsTyping(false);
      }}
      inputValue={!_.isNil(inputValue) ? inputValue : ""}
      onInputChange={(e, newInputValue, reason) => {
        setIsTyping(true);
        handleValueTyping(newInputValue);
      }}
      onBlur={() => {
        //console.log("onBlur 1 false");
        setOpen(false);
        setIsTyping(false);
      }}
      filterOptions={customFilterOptions}
      renderOption={(option, { inputValue }) => {
        const matches = match(option[mainAccessor?.from], inputValue, {
          insideWords: true,
        });
        const parts = parse(option[mainAccessor?.from], matches);

        const otherCols = _.omit(option, [mainAccessor?.from]);

        const other =
          colsToShow && colsToShow.length > 0 && otherCols
            ? _.pickBy(
                Object.fromEntries(
                  colsToShow.map((col) => [col, otherCols[col]])
                ),
                (v) => !_.isNil(v)
              )
            : otherCols;

        return (
          <div>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{
                  fontWeight: part.highlight ? "bold" : "lighter",
                  textDecoration: part.highlight ? "underline" : "none",
                }}
              >
                {part.text}
              </span>
            ))}
            {!_.isNil(other) &&
              !_.isEmpty(other) &&
              Object.entries(other).map(([k, v], index) => {
                const matchesOther = match(v, inputValue, {
                  insideWords: true,
                });
                const partsOther = parse(v, matchesOther);
                if (partsOther && partsOther.length > 0) {
                  return (
                    <span key={index}>
                      {" | "}
                      {partsOther.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? "bold" : "lighter",
                            textDecoration: part.highlight
                              ? "underline"
                              : "none",
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </span>
                  );
                } else {
                  return "";
                }
              })}
          </div>
        );
      }}
      disabled={
        inputEnabled === false ||
        (calculatedInputProps && calculatedInputProps?.enabled === false) ||
        disableDueChangeSavedValue
      }
      renderInput={(params) => (
        <TextField
          {...params}
          style={inputStyles}
          //OnBlur to handling the lost of focus
          onBlur={handleBlurHelper}
          //onKeyDown to handling each key press for F1
          onKeyDown={(e) => handleKeyPress(e)}
          InputProps={{
            ...params?.InputProps,
            inputRef: internalRef,
            endAdornment: (
              <SelectionAdornment
                selectionServices={selectionServices}
                completeIdControl={completeIdControl}
                isSettingValueInServer={isSettingValueInServer}
                calculatedInputProps={calculatedInputProps}
                disableDueChangeSavedValue={disableDueChangeSavedValue}
                handleContextMenu={handleContextMenu}
                inputEnabled={inputEnabled}
                hasHelper={true}
              />
            ),
          }}
        />
      )}
    />
  );
}
