import React, { useState, useEffect, useRef } from "react";
import { Button, Icon, Tooltip, useTheme } from "@material-ui/core";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  INITIAL_OPEN_EXTERNAL_FORM_STATE,
  TOAST_CONTAINER_FORM_CONTAINER,
  FORM_OPERATION_NEW,
  //FORM_OPERATION_VIEW,
} from "../../../util/Constants";
import {
  //defineInputEnabledByOperation,
  getCompletePathGridItem,
  resolveFieldAgainstShadowStatus,
  resolveValuesFieldAgainsShadowStatus,
} from "../../../util/UtilForm";
import ExternalFormContainerValues from "../externalFormContainer/ExternalFormContainerValues";

const commonIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
  margin: 0,
  fontSize: "1rem",
};

const DEFAULT_ICON = "fas fa-external-link-alt";

export default function EditableCellLaunchExternalForm(props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    row,
    column,
    formContext,
    //operation,
    value,
  } = props;

  const { section, block, listName, shadowStatus, isBlockDisabledByWizard } =
    formContext;
  const { id } = column;
  const { index } = row;

  const buttonRef = useRef(null);
  //const [isEnabled, setIsEnabled] = useState(true);
  const [calculatedInputProps, setCalculatedInputProps] = useState(null);
  const [openExternalForm, setOpenExternalForm] = useState(
    INITIAL_OPEN_EXTERNAL_FORM_STATE
  );

  let completeIdControl = getCompletePathGridItem(
    section,
    block,
    `_${id}`,
    listName,
    column,
    index + 1
  );

  //Check if input is enabled or not
  // useEffect(() => {
  //   const checkInputEnabled = defineInputEnabledByOperation(
  //     operation,
  //     column?.canEdit,
  //     column?.canNew
  //   );
  //   if (operation === FORM_OPERATION_VIEW) {
  //     setIsEnabled(true);
  //   } else {
  //     if (!_.isNil(checkInputEnabled)) {
  //       setIsEnabled(checkInputEnabled);
  //     }
  //   }
  //   if (
  //     !_.isNil(calculatedInputProps) &&
  //     !_.isNil(calculatedInputProps.enabled)
  //   ) {
  //     setIsEnabled(calculatedInputProps.enabled);
  //   }
  // }, [operation, column, calculatedInputProps]);

  //Clearing function
  useEffect(() => {
    checkPresentationInfo(value);
  }, [value]);

  function checkPresentationInfo(value) {
    if (!_.isNil(value) && !_.isNil(value.presentationInfo)) {
      const { presentationInfo } = value;
      if (presentationInfo && presentationInfo.reset) {
        setCalculatedInputProps(null);
      } else {
        setCalculatedInputProps(presentationInfo);
      }
    } else {
      setCalculatedInputProps(null);
    }
  }

  const isInputDisabled =
    (!_.isNil(calculatedInputProps) &&
      !_.isNil(calculatedInputProps.enabled) &&
      calculatedInputProps.enabled === false) ||
    isBlockDisabledByWizard === true;

  const launcherDef =
    column && !_.isNil(column.launcherDef) && !_.isEmpty(column.launcherDef)
      ? column.launcherDef
      : {
          openFormConfig: null,
          icon: DEFAULT_ICON,
          label: null,
          tooltip: t("QUERY_OPEN_FORM_ENABLED"),
        };

  //Input styles
  const controlStyles = {
    padding: 3,
    color:
      calculatedInputProps &&
      calculatedInputProps.color &&
      calculatedInputProps.color,
    fontSize:
      calculatedInputProps &&
      calculatedInputProps.fontSize &&
      calculatedInputProps.fontSize,
    backgroundColor:
      calculatedInputProps && calculatedInputProps.backgroundColor
        ? calculatedInputProps.backgroundColor
        : `${theme.palette.content.mainColor}AA`,
    fontWeight:
      calculatedInputProps &&
      calculatedInputProps.fontWeight &&
      calculatedInputProps.fontWeight,
    fontStyle:
      calculatedInputProps &&
      calculatedInputProps.fontStyle &&
      calculatedInputProps.fontStyle,
    width: "100%",
    borderRadius: 5,
    marginInline: 5,
    "&:hover": {
      backgroundColor: "green",
      color: "#FFF",
    },
  };

  async function handleClick() {
    if (!_.isNil(launcherDef?.openFormConfig)) {
      const {
        idForm,
        path,
        defaulOpertion,
        values,
        overrideOps,
        defaultWidth,
        defaultHeight,
      } = launcherDef?.openFormConfig;

      if (_.isNil(idForm) || _.isEmpty(idForm)) {
        const toastText = t("EXTERNAL_FORM_ERROR_OPENING_FIELD_MISSING", {
          q: "idForm",
        });
        toast.error(toastText, {
          containerId: TOAST_CONTAINER_FORM_CONTAINER,
          toastId: "idForm-empty",
          autoClose: false,
        });
        return;
      }

      if (_.isNil(path) || _.isEmpty(path)) {
        const toastText = t("EXTERNAL_FORM_ERROR_OPENING_FIELD_MISSING", {
          q: "path",
        });
        toast.error(toastText, {
          containerId: TOAST_CONTAINER_FORM_CONTAINER,
          toastId: "path-empty",
          autoClose: false,
        });
        return;
      }

      if (_.isNil(values) || _.isEmpty(values)) {
        const toastText = t("EXTERNAL_FORM_ERROR_OPENING_FIELD_MISSING", {
          q: "values",
        });
        toast.error(toastText, {
          containerId: TOAST_CONTAINER_FORM_CONTAINER,
          toastId: "values-empty",
          autoClose: false,
        });
        return;
      }

      const valuesToSet = resolveValuesFieldAgainsShadowStatus({
        values,
        shadowStatus,
        completeIdControl,
        line: index,
      });

      const idFormToSet = resolveFieldAgainstShadowStatus({
        field: idForm,
        shadowStatus,
        completeIdControl,
        line: index,
      });

      const pathToSet = resolveFieldAgainstShadowStatus({
        field: path,
        shadowStatus,
        completeIdControl,
        line: index,
      });

      setOpenExternalForm({
        open: true,
        operation: defaulOpertion,
        values: valuesToSet,
        idForm: idFormToSet,
        path: pathToSet,
        overrideOps,
        defaultWidth,
        defaultHeight,
      });
    }
  }

  function handleBackClick() {
    setOpenExternalForm(INITIAL_OPEN_EXTERNAL_FORM_STATE);
  }

  return (
    <>
      {openExternalForm.open && (
        <ExternalFormContainerValues
          openModal={openExternalForm.open}
          handleOpenModalForm={handleBackClick}
          operation={openExternalForm.operation || FORM_OPERATION_NEW}
          path={openExternalForm.path}
          idForm={openExternalForm.idForm}
          values={openExternalForm.values}
          overrideOps={openExternalForm.overrideOps}
          defaultWidth={openExternalForm.defaultWidth}
          defaultHeight={openExternalForm.defaultHeight}
        />
      )}
      <Tooltip
        title={isInputDisabled ? "" : launcherDef?.tooltip}
        disableHoverListener={isInputDisabled}
      >
        <Button
          onClick={handleClick}
          disabled={isInputDisabled}
          ref={buttonRef}
          id={completeIdControl}
          style={controlStyles}
        >
          {!_.isNil(launcherDef?.label) && launcherDef?.label !== ""
            ? launcherDef?.label
            : null}
          <Icon
            className={launcherDef?.icon || DEFAULT_ICON}
            style={
              !_.isNil(launcherDef?.label) && launcherDef?.label !== ""
                ? { ...commonIcon, marginLeft: 5 }
                : commonIcon
            }
          />
        </Button>
      </Tooltip>
    </>
  );
}
