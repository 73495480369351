import React, { useContext, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import _, { isNil } from "lodash";
import { Hidden } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { v4 as uuid } from "uuid";

import { useStyles } from "./LayoutStyles";
import { AuthContext } from "../../core/providers/AuthContext";
import { DrawerContext } from "../../core/providers/DrawerContext";
import {
  WORKSPACE_TAB,
  TAB_MAPPING,
  BREAKPOINT_LG,
  TOAST_CONTAINER_LAYOUT,
  ENV_PAGE_TITLE,
  MULTI_ITEM_ENABLED,
  FORM_OPERATION_EXECUTION,
} from "../../util/Constants";
import useWindowDimensions from "../../core/hooks/useWindowDimensions";
import LogoutConfirmationDialog from "../../components/dialogs/logoutConfirmationDialog/LogoutConfirmationDialog";
import { getFirstNPos } from "../../util/UtilFormat";
import { LayoutContext } from "../../core/providers/LayoutContext";
import NotificationsDock from "../../components/notifications/NotificationsDock";
import { WorkspaceContext } from "../../core/providers/WorkspaceContext";
import DesktopMenu from "./components/DesktopMenu";
import MobileMenu from "./components/MobileMenu";
import SideBar from "./components/sidebar/SideBar";
import { useRequestHeaders } from "../../core/hooks/useRequestHeaders";
import { useAccessesStore } from "../../core/stores/AccessesStore";
import CustomAppBar from "./components/appBar/CustomAppBar";
import { useInteractiveItemStore } from "../../core/stores/InteractiveItemStore";
import TabsContainer from "./components/tabs/TabsContainer";
import SubTabsContainer from "./components/tabs/SubTabsContainer";

function Layout({ children }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { auth, logout, changeCompany } = useContext(AuthContext);
  const { companies } = auth;
  const { drawerInfo, setDrawerInfo, cleanDrawerState, changeHeaderImg } =
    useContext(DrawerContext);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedChildIndex, setselectedChildIndex] = useState("");
  const [openItems, setOpenItems] = useState(drawerInfo.openItems || {});
  const wd = useWindowDimensions();
  const [openLogoutConfirmation, setOpenLogoutConfirmation] = useState(false);
  const { changeIsDrawerOpen, activeTab, changeActiveTab, showSubItemsInForm } =
    useContext(LayoutContext);

  const classes = useStyles({ openDrawer });
  let location = useLocation();
  const { addToFormHistory } = useContext(WorkspaceContext);

  const REQUEST_HEADERS = useRequestHeaders();

  const {
    menuItems,
    fetchingMenu,
    setFetchingMenu,
    fetchMenuDef,
    callServerToGetProcessesList,
    callServerToGetFavItems,
  } = useAccessesStore((state) => ({
    menuItems: state.menuItems,
    fetchingMenu: state.fetchingMenu,
    setFetchingMenu: state.setFetchingMenu,
    fetchMenuDef: state.fetchMenuDef,
    callServerToGetProcessesList: state.callServerToGetProcessesList,
    callServerToGetFavItems: state.callServerToGetFavItems,
  }));

  const {
    addTabItem,
    addSubItem,
    changeActiveTabItem,
    changeActiveSubItem,
    activeTabItem,
    subItems,
    activeSubItem,
  } = useInteractiveItemStore();

  useEffect(() => {
    changeIsDrawerOpen(openDrawer);
  }, [openDrawer, changeIsDrawerOpen]);

  useEffect(() => {
    if (
      activeTab &&
      activeTab === WORKSPACE_TAB &&
      activeTabItem &&
      subItems &&
      !_.isEmpty(subItems) &&
      activeSubItem &&
      activeSubItem.size > 0 &&
      location.pathname === "/"
    ) {
      const subItemTab = subItems.filter((s) => s?.parentId === activeTabItem);
      if (!_.isNil(subItemTab) && !_.isEmpty(subItemTab)) {
        const activeSubItemInMap = activeSubItem.get(activeTabItem);
        if (!_.isNil(activeSubItemInMap)) {
          history.push(activeSubItemInMap.id, { ...activeSubItemInMap });
        } else {
          const subItemToPush = subItemTab[0];
          if (!_.isNil(subItemToPush)) {
            history.push(subItemToPush.route, { ...subItemToPush });
          }
        }
      }
    }
    //eslint-disable-next-line
  }, [activeTab, activeTabItem]);

  /*
   * Effect to toggle mobile drawer when page changes breakpoint size
   */
  useEffect(() => {
    const { width } = wd;
    if (width < BREAKPOINT_LG && openDrawer === true) {
      setOpenDrawer(false);
    }
    return () => null;
  }, [wd, openDrawer]);

  /**
   * Refresh menu on each re-render of component
   */
  useEffect(() => {
    if (
      auth &&
      auth.company &&
      auth.company.codCompany &&
      (_.isNil(auth.mustChangePass) || auth.mustChangePass === false)
    ) {
      fetchMenuDef({ t, REQUEST_HEADERS, logout });
      callServerToGetProcessesList({ t, REQUEST_HEADERS, logout });
      callServerToGetFavItems({ REQUEST_HEADERS, t, logout });
    }
    return () => null;
    // eslint-disable-next-line
  }, [auth]);

  // Function to Push Page with history
  const pushPage = (params) => {
    const isExecutionParam =
      !_.isNil(params?.isExecution) && params?.isExecution === true;

    const paramsToSet = isExecutionParam
      ? {
          ...params,
          route: `${params?.route}/exe`,
        }
      : params;
    addToFormHistory(paramsToSet);
    addTabItem(paramsToSet);
    const uniqueIdForExe = uuid();
    const sub = isExecutionParam
      ? {
          ...paramsToSet,
          parentId: paramsToSet.route,
          propText: paramsToSet?.idForm,
          count_name: paramsToSet?.idForm,
          count_number: uniqueIdForExe,
          entityName: `${paramsToSet?.idForm}_${uniqueIdForExe}`,
          operation: FORM_OPERATION_EXECUTION,
          id: paramsToSet.route,
        }
      : {
          ...paramsToSet,
          parentId: paramsToSet.route,
          propText: t("MULTI_ITEM_SAVED_RECORDS"),
        };
    changeActiveTabItem(paramsToSet.route);
    addSubItem(sub);
    changeActiveSubItem(sub);
    if (isExecutionParam) {
      history.push(paramsToSet.route, {
        path: paramsToSet?.path,
        idForm: paramsToSet?.idForm,
        count_name: sub?.count_name,
        count_number: sub?.count_number,
      });
    } else {
      history.push(paramsToSet.route, {
        path: paramsToSet?.path,
        idForm: paramsToSet?.idForm,
      });
    }
  };

  //Handle drawer state
  const handleDrawerOpenState = (toSet) => {
    const { width } = wd;
    if (width > BREAKPOINT_LG) {
      setOpenDrawer(toSet);
    } else {
      setMobileOpen(toSet);
    }

    //openOrCloseDrawer(toSet);
  };

  // Function to handle click and push page from PARENT
  const handleClick = (params) => {
    const newOpenItems = handleOpenItem(params);

    checkHeaderImg(params, newOpenItems);

    //Has nested levels
    if (params.children === true) {
      //If actual spanded item, collapse it
      if (selectedIndex === params.index) {
        setDrawerInfo({
          selectedChildIndex: "",
          selectedGrandIndex: "",
          selectedIndex: "",
          selectedLastIndex: "",
          openItems: newOpenItems,
        });
        setSelectedIndex("");
      } else {
        //If not, expand it
        setDrawerInfo({
          //...drawerInfo,
          selectedChildIndex: "",
          selectedGrandIndex: "",
          selectedLastIndex: "",
          selectedIndex: params.index,
          openItems: newOpenItems,
        });
        setSelectedIndex(params.index);
      }
    } else {
      //Has not nested values, set in state and push page
      setDrawerInfo({
        selectedChildIndex: "",
        selectedGrandIndex: "",
        selectedLastIndex: "",
        selectedIndex: params.index,
        openItems: newOpenItems,
      });
      if (mobileOpen) {
        setMobileOpen(false);
      }

      //Push page
      pushPage({
        route: params.route,
        propText: params.propText,
        path: params?.formPath,
        idForm: params?.formName,
        name: params?.name,
        isExecution: params?.isExecution,
        defaultPinned: params?.defaultPinned,
      });
    }
  };

  // Function to handle click and push page from CHILD
  const handleChildClick = (params) => {
    const newOpenItems = handleOpenItem(params);
    const {
      children,
      childIndex,
      index,
      route,
      propText,
      formPath,
      formName,
      name,
      isExecution,
      defaultPinned,
    } = params;
    const ownParent = getFirstNPos(1, childIndex);

    checkHeaderImg(params, newOpenItems);

    // If has CHILDREN (folder)
    if (children === true) {
      if (selectedChildIndex === childIndex) {
        setDrawerInfo({
          ...drawerInfo,
          selectedChildIndex: "",
          openItems: newOpenItems,
        });
        setselectedChildIndex("");
      } else {
        setDrawerInfo({
          ...drawerInfo,
          selectedChildIndex: childIndex,
          selectedIndex:
            !_.isNil(index) && index >= 0 ? index : drawerInfo.selectedIndex,
          openItems: newOpenItems,
        });
        setselectedChildIndex(childIndex);
        if (!_.isNil(index) && index >= 0) {
          setSelectedIndex(index);
        }
      }
    } else {
      // If it is final item
      if (!_.isNil(index) && index >= 0) {
        setSelectedIndex(index);
      }
      setselectedChildIndex(childIndex);
      setDrawerInfo({
        ...drawerInfo,
        selectedChildIndex: childIndex,
        selectedIndex: ownParent,
        openItems: newOpenItems,
        selectedGrandIndex: null,
        selectedLastIndex: null,
      });
      if (mobileOpen) {
        setMobileOpen(false);
      }
      pushPage({
        route: route,
        propText: propText,
        path: formPath,
        idForm: formName,
        name,
        isExecution,
        defaultPinned,
      });
    }
  };

  // Function to handle click and push page from GRAND
  const handleGrandClick = (params) => {
    const { grandIndex, route, propText, index, children } = params;
    const newOpenItems = handleOpenItem(params);
    const ownGrandpa = getFirstNPos(1, grandIndex);
    const ownParent = getFirstNPos(2, grandIndex);

    checkHeaderImg(params, newOpenItems);

    if (children === true) {
      if (selectedChildIndex === grandIndex) {
        setDrawerInfo({
          ...drawerInfo,
          selectedChildIndex: "",
          openItems: newOpenItems,
        });
        setselectedChildIndex("");
      } else {
        setDrawerInfo({
          ...drawerInfo,
          selectedChildIndex: grandIndex,
          selectedIndex:
            !_.isNil(index) && index >= 0 ? index : drawerInfo.selectedIndex,
          openItems: newOpenItems,
        });
        setselectedChildIndex(grandIndex);
        if (!_.isNil(index) && index >= 0) {
          setSelectedIndex(index);
        }
      }
    } else {
      //NUEVO
      if (!_.isNil(index) && index >= 0) {
        setSelectedIndex(index);
      }
      if (!_.isNil(grandIndex) && grandIndex >= 0) {
        setselectedChildIndex(grandIndex);
      }
      //NUEVO
      setDrawerInfo({
        ...drawerInfo,
        selectedGrandIndex: grandIndex,
        //NUEVO
        selectedChildIndex: ownParent,
        selectedIndex: ownGrandpa,
        openItems: newOpenItems,
        //NUEVO
      });
      if (mobileOpen) {
        setMobileOpen(false);
      }
      pushPage({
        route: route,
        propText: propText,
        path: params?.formPath,
        idForm: params?.formName,
        name: params?.name,
        isExecution: params?.isExecution,
        defaultPinned: params?.defaultPinned,
      });
    }
  };

  // Function to handle click and push page from GRAND
  const handleLastClick = (params) => {
    const { lastIndex, route, propText, index, childIndex, grandIndex } =
      params;
    const newOpenItems = handleOpenItem(params);
    const ownBis = getFirstNPos(1, lastIndex);
    const ownGrandpa = getFirstNPos(2, lastIndex);
    const ownParent = getFirstNPos(3, lastIndex);

    checkHeaderImg(params, newOpenItems);

    //NUEVO
    if (!_.isNil(index) && index >= 0) {
      setSelectedIndex(index);
    }
    if (!_.isNil(childIndex) && childIndex >= 0) {
      setselectedChildIndex(childIndex);
    }

    if (!_.isNil(grandIndex) && grandIndex >= 0) {
      setselectedChildIndex(grandIndex);
    }
    //NUEVO
    setDrawerInfo({
      ...drawerInfo,
      selectedLastIndex: lastIndex,
      selectedGrandIndex: ownParent,
      //NUEVO
      selectedChildIndex: ownGrandpa,
      selectedIndex: ownBis,
      openItems: newOpenItems,
      //NUEVO
    });
    if (mobileOpen) {
      setMobileOpen(false);
    }
    pushPage({
      route: route,
      propText: propText,
      path: params?.formPath,
      idForm: params?.formName,
      name: params?.name,
      isExecution: params?.isExecution,
      defaultPinned: params?.defaultPinned,
    });
  };

  function checkHeaderImg(params, newOpenItems) {
    if (
      params?.headerImg &&
      params.headerImg !== "" &&
      newOpenItems &&
      newOpenItems[params?.id] === true
    ) {
      changeHeaderImg(params?.headerImg);
    } else {
      changeHeaderImg(null);
    }
  }

  /**
   * Function to handle open items
   */
  function handleOpenItem(item) {
    if (item && item.id !== null && item.id !== undefined) {
      const { id } = item;
      const isIn = _.has(openItems, id);
      if (isIn) {
        const valueToSet = !openItems[id];
        const toSet = {
          ...openItems,
          [id]: valueToSet,
        };
        setOpenItems(toSet);
        return toSet;
      } else {
        const toSet = openItems ? { ...openItems, [id]: true } : { [id]: true };
        setOpenItems(toSet);
        return toSet;
      }
    }
  }

  /**
   * Function to handle logout only if alert is confirmed
   */
  const handleLogout = (confirmLogout) => {
    if (confirmLogout === true) {
      logout();
    }
    setOpenLogoutConfirmation(false);
  };

  async function handleComboCompany(event) {
    setFetchingMenu(true);
    const value = event?.target?.value;
    if (value) {
      const companyToSet = companies.find((comp) => comp.codCompany === value);
      if (companyToSet) {
        const changeCompanyResult = await changeCompany(companyToSet);
        let toastContent = null;
        if (
          changeCompanyResult &&
          !_.isNil(changeCompanyResult.ok) &&
          changeCompanyResult.ok === true
        ) {
          const successLoginText = t("CLIENT_CHANGE_SUCCESS");
          toast.success(successLoginText, {
            containerId: TOAST_CONTAINER_LAYOUT,
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (
          changeCompanyResult &&
          !_.isNil(changeCompanyResult.ok) &&
          changeCompanyResult.ok === false &&
          !_.isNil(changeCompanyResult.msg)
        ) {
          toastContent = changeCompanyResult.msg;
        } else {
          toastContent = t("ERROR_RESOURCE_NOT_FOUND_TEXT");
        }

        if (!_.isNil(toastContent)) {
          toast.error(toastContent, {
            containerId: TOAST_CONTAINER_LAYOUT,
            position: "bottom-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        cleanDrawerState();
      }
    }
    setFetchingMenu(false);
  }

  function handleTabClick(newTab, newDrawerState) {
    if (!isNil(newTab) && WORKSPACE_TAB === newTab) {
      if (activeSubItem.has(activeTabItem)) {
        const subToMove = activeSubItem.get(activeTabItem);
        const routeToMove = subToMove?.id || subToMove?.route;
        history.push(routeToMove, subToMove);
      } else {
        history.push(TAB_MAPPING[newTab], {
          newTab,
          newDrawerState,
        });
      }
    } else {
      history.push(TAB_MAPPING[newTab], {
        newTab,
        newDrawerState,
      });
    }

    changeHeaderImg(null);
  }

  useEffect(() => {
    if (location) {
      const { pathname, state } = location;
      if (pathname && state) {
        const { newTab, newDrawerState } = state;
        if (!_.isNil(newTab) && !_.isNil(newDrawerState)) {
          if (activeTab === WORKSPACE_TAB) {
            setOpenDrawer(newDrawerState);
          }
          changeActiveTab(newTab);
        } else if (
          _.isNil(newTab) &&
          _.isNil(newDrawerState) &&
          !_.isNil("/") &&
          pathname !== "/" &&
          !pathname.startsWith("/inbox")
        ) {
          //setOpenDrawer(true);
          changeActiveTab(WORKSPACE_TAB);
        }
      }
    }
    //eslint-disable-next-line
  }, [location]);

  return (
    <div className={classes.rootContainer}>
      <Helmet>
        <title>DSuite {ENV_PAGE_TITLE}</title>
      </Helmet>
      <LogoutConfirmationDialog
        open={openLogoutConfirmation}
        handleLogout={handleLogout}
      />
      <NotificationsDock />
      <div id="content-root" className={classes.contentRoot}>
        <CustomAppBar
          handleComboCompany={handleComboCompany}
          handleDrawerOpenState={handleDrawerOpenState}
          openDrawer={openDrawer}
          handleTabClick={handleTabClick}
          menuItems={menuItems}
          pushPage={pushPage}
          setOpenLogoutConfirmation={setOpenLogoutConfirmation}
          handleProcessClick={handleClick}
        />

        <Hidden smDown>
          <SideBar
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            openDrawer={openDrawer}
            handleDrawerOpenState={handleDrawerOpenState}
            handleComboCompany={handleComboCompany}
            handleProcessClick={handleClick}
          />
        </Hidden>

        {/* MENUS (MOBILE AND DESKTOP) */}
        <nav className={classes.drawerContainer} aria-label="menu-folders">
          {/* MOBILE MENU */}
          <MobileMenu
            mobileOpen={mobileOpen}
            fetchingMenu={fetchingMenu}
            menuItems={menuItems}
            pushPage={pushPage}
            fetchMenuDef={fetchMenuDef}
            handleClick={handleClick}
            handleChildClick={handleChildClick}
            handleGrandClick={handleGrandClick}
            handleLastClick={handleLastClick}
            handleDrawerOpenState={handleDrawerOpenState}
            setMobileOpen={setMobileOpen}
          />

          {/* DESKTOP MENU */}
          <DesktopMenu
            fetchingMenu={fetchingMenu}
            menuItems={menuItems}
            openDrawer={openDrawer}
            pushPage={pushPage}
            fetchMenuDef={fetchMenuDef}
            handleClick={handleClick}
            handleChildClick={handleChildClick}
            handleGrandClick={handleGrandClick}
            handleLastClick={handleLastClick}
          />
        </nav>

        {/* CONTENT ROOT */}
        <main className={classes.solutionContent}>
          {MULTI_ITEM_ENABLED && (
            <Hidden smDown>
              <TabsContainer />
            </Hidden>
          )}
          {children}
          {MULTI_ITEM_ENABLED && !showSubItemsInForm && (
            <Hidden smDown>
              <SubTabsContainer />
            </Hidden>
          )}
        </main>
      </div>
    </div>
  );
}

export default Layout;
