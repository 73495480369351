import { isEmpty, isNil } from "lodash";
import {
  SERVER_HOST,
  API_DASHBOARD,
  REST_HEADERS,
  TOKEN_HEADER,
  TOKEN_INIT,
  METHOD_GET,
  API_KPI,
  METHOD_POST,
  METHOD_DELETE,
  API_SOLUTIONS,
  API_USER_CONFIG,
  API_CUSTOMIZATION,
} from "../../util/Constants";

async function getDashboard({ REQUEST_HEADERS }) {
  const url = SERVER_HOST() + API_DASHBOARD + "/get";

  const response = await fetch(url, {
    method: METHOD_GET,
    headers: REQUEST_HEADERS,
  })
    .then((res) => res.json())
    .then((result) => {
      return result;
    })
    .catch(() => {
      return { ok: false };
    });

  return response;
}

async function getStatusApps(token, url) {
  const REQUEST_HEADERS = {
    ...REST_HEADERS,
    [TOKEN_HEADER]: `${TOKEN_INIT}${token}`,
  };

  const response = await fetch(url, {
    method: METHOD_GET,
    headers: REQUEST_HEADERS,
  })
    .then((res) => res.json())
    .then((result) => {
      return result;
    })
    .catch(() => {
      return { ok: false };
    });

  return response;
}

async function getNewFrontVersion(token, url) {
  const REQUEST_HEADERS = {
    ...REST_HEADERS,
    [TOKEN_HEADER]: `${TOKEN_INIT}${token}`,
  };

  const response = await fetch(url, {
    method: METHOD_GET,
    headers: REQUEST_HEADERS,
  })
    .then((res) => res.json())
    .then((result) => {
      return result;
    })
    .catch(() => {
      return { ok: false };
    });

  return response;
}

async function getNewFrontVersionCloud(url) {
  const response = await fetch(url, {
    method: METHOD_GET,
  })
    .then((res) => res.text())
    .then((result) => {
      return {
        ok: true,
        dataResponse: result
          .trim()
          .replace("\r\n", "")
          .replace("\r", "")
          .replace("\n", ""),
      };
    })
    .catch(() => {
      return { ok: false };
    });

  return response;
}

async function getKpi({ REQUEST_HEADERS, kpiName }) {
  const url = `${SERVER_HOST()}${API_KPI}/getKPI?kpiName=${kpiName}`;

  const response = await fetch(url, {
    method: METHOD_GET,
    headers: REQUEST_HEADERS,
  })
    .then((res) => res.json())
    .then((result) => {
      return result;
    })
    .catch(() => {
      return { ok: false };
    });

  return response;
}

async function getKpiDetail({ REQUEST_HEADERS, kpiName }) {
  const url = `${SERVER_HOST()}${API_KPI}/getDetail?kpiName=${kpiName}`;

  const response = await fetch(url, {
    method: METHOD_GET,
    headers: REQUEST_HEADERS,
  })
    .then(async (res) => {
      const json = await res.json();
      return {
        status: res.status,
        ...json,
      };
    })
    .catch(() => {
      return {
        status: 500,
        json: { ok: false },
      };
    });

  return response;
}

async function saveDashboard({ REQUEST_HEADERS, homeName, body }) {
  const homeNameParam =
    !isNil(homeName) && !isEmpty(homeName) ? `?homeName=${homeName}` : "";

  const url = `${SERVER_HOST()}${API_DASHBOARD}/save${homeNameParam}`;

  const response = await fetch(url, {
    method: METHOD_POST,
    headers: REQUEST_HEADERS,
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((result) => {
      return result;
    })
    .catch(() => {
      return { ok: false };
    });

  return response;
}

async function returnToDefault({ REQUEST_HEADERS, homeName }) {
  const homeNameParam =
    !isNil(homeName) && !isEmpty(homeName) ? `?homeName=${homeName}` : "";

  const url = `${SERVER_HOST()}${API_DASHBOARD}/returnToDefault${homeNameParam}`;

  const response = await fetch(url, {
    method: METHOD_DELETE,
    headers: REQUEST_HEADERS,
  })
    .then((res) => res.json())
    .then((result) => {
      return result;
    })
    .catch(() => {
      return { ok: false };
    });

  return response;
}

async function getSolutionsTasks({ REQUEST_HEADERS }) {
  const url = SERVER_HOST() + API_SOLUTIONS + "/get/tasks/counts";

  try {
    const response = await fetch(url, {
      method: METHOD_GET,
      headers: REQUEST_HEADERS,
    });

    const responseData = await response.json();

    return {
      status: response.status,
      ...responseData,
    };
  } catch (error) {
    return {
      status: 500,
      ok: false,
      error: error.message,
    };
  }
}

async function getUserConfigFavs({ REQUEST_HEADERS }) {
  const url = SERVER_HOST() + API_USER_CONFIG + "/get-favs";

  try {
    const response = await fetch(url, {
      method: METHOD_GET,
      headers: REQUEST_HEADERS,
    });

    const responseData = await response.json();

    return {
      status: response.status,
      ...responseData,
    };
  } catch (error) {
    return {
      status: 500,
      ok: false,
      error: error.message,
    };
  }
}

async function saveUserConfigFav({ REQUEST_HEADERS, id }) {
  const url = `${SERVER_HOST()}${API_USER_CONFIG}/add-fav-item?id=${id}`;

  try {
    const response = await fetch(url, {
      method: METHOD_POST,
      headers: REQUEST_HEADERS,
    });

    const responseData = await response.json();

    return {
      status: response.status,
      ...responseData,
    };
  } catch (error) {
    return {
      status: 500,
      ok: false,
      error: error.message,
    };
  }
}

async function deleteUserConfigFav({ REQUEST_HEADERS, id }) {
  const url = `${SERVER_HOST()}${API_USER_CONFIG}/remove-fav-item?id=${id}`;

  try {
    const response = await fetch(url, {
      method: METHOD_POST,
      headers: REQUEST_HEADERS,
    });

    const responseData = await response.json();

    return {
      status: response.status,
      ...responseData,
    };
  } catch (error) {
    return {
      status: 500,
      ok: false,
      error: error.message,
    };
  }
}

async function getCustomHeaderLogoByCompany({ REQUEST_HEADERS, company }) {
  const url = `${SERVER_HOST()}${API_CUSTOMIZATION}/custom-header-logo-by-company?company=${company}`;

  const response = await fetch(url, {
    method: METHOD_GET,
    headers: REQUEST_HEADERS,
  })
    .then(async (res) => {
      const data = await res.json();
      if (!isNil(data) && !isEmpty(data)) {
        return { status: res.status, ...data };
      } else {
        return { status: res.status };
      }
    })
    .catch((error) => {
      return { ok: false, error: error.message };
    });

  return response;
}

export {
  getDashboard,
  getStatusApps,
  getNewFrontVersion,
  getNewFrontVersionCloud,
  getKpi,
  getKpiDetail,
  saveDashboard,
  returnToDefault,
  getSolutionsTasks,
  getUserConfigFavs,
  saveUserConfigFav,
  deleteUserConfigFav,
  getCustomHeaderLogoByCompany,
};
