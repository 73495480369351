import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, Icon, IconButton } from "@material-ui/core";

import { INDICATORS_TAB } from "../../../../util/Constants";
import { useStyles, iconAuto } from "./styles";

export default function IndicatorsButton(props) {
  const { activeTab, handleTabClick } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Tooltip title={t("TAB_INDICATORS")} placement="right">
      <div className={classes.envButton}>
        <IconButton
          id="tab_indicators_button"
          className={
            activeTab === INDICATORS_TAB ? classes.activeTab : classes.normalTab
          }
          onClick={() => {
            handleTabClick(INDICATORS_TAB, true);
          }}
        >
          <Icon className="fas fa-tachometer-alt" style={iconAuto} />
        </IconButton>
      </div>
    </Tooltip>
  );
}
