import React, { useCallback, useContext, useState } from "react";
import { Icon, IconButton, Tooltip, Typography } from "@material-ui/core";
import {
  endsWith,
  filter,
  find,
  includes,
  isArray,
  isEmpty,
  isNil,
  isNumber,
  isObject,
  isString,
  lastIndexOf,
  some,
  toString,
} from "lodash";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { useStyles, iconAuto, closeIcon } from "./TabStyles";
import {
  FORM_OPERATION_EXECUTION,
  HOME_TAB,
  TAB_MAPPING,
  WORKSPACE_TAB,
} from "../../../../util/Constants";
import { LayoutContext } from "../../../../core/providers/LayoutContext";
import {
  findNearestTabItemToMove,
  useInteractiveItemStore,
} from "../../../../core/stores/InteractiveItemStore";
import CloseTabDialog from "./dialogs/CloseTabDialog";
import { getLocaleText } from "../../../../util/UtilTraduction";
import { useHorizontalScroll } from "../../../../core/hooks/useHorizontalScroll";
import { useRef } from "react";
import { useNavigation } from "../../../../core/hooks/useNavigation";
import CloseAllTabsDialog from "./dialogs/CloseAllTabsDialog";
import { useRecordPanelStore } from "../../../../core/stores/RecordPanelStore";
import SubHasChangedIcon from "./inner/SubHasChangedIcon";
import { useAnalysisStore } from "../../../../core/stores/solutions/analysis/AnalysisStore";
import { useReportsStore } from "../../../../core/stores/ReportsStore";
import { useAccessesStore } from "../../../../core/stores/AccessesStore";
import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";
import { notifyServerCleanFormInstanceId } from "../../../../util/UtilForm";

const iconBtnStyles = {
  padding: 5,
};

export default function TabsContainer() {
  const horizontalRef = useRef();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const {
    tabItems,
    subItems,
    changeActiveTabItem,
    removeTabItem,
    activeTabItem,
    activeSubItem,
    activeSubItemObject,
    removeAllTabItems,
    clearActiveTabItem,
    makeTabFinal,
  } = useInteractiveItemStore();
  const REQUEST_HEADERS = useRequestHeaders();

  const flattenedMenuItems = useAccessesStore(
    (state) => state.flattenedMenuItems
  );

  const isUpdatingFavItems = useAccessesStore(
    (state) => state.isUpdatingFavItems
  );
  const favItems = useAccessesStore((state) => state.favItems);
  const callServerToAddFavItem = useAccessesStore(
    (state) => state.callServerToAddFavItem
  );
  const callServerToDeleteFavItem = useAccessesStore(
    (state) => state.callServerToDeleteFavItem
  );

  const clearKeyFromRecordListData = useRecordPanelStore(
    (state) => state.clearKeyFromRecordListData
  );

  const { removeAnalysisData } = useAnalysisStore();

  const { removeReportData } = useReportsStore();

  const { activeTab, changeActiveTab } = useContext(LayoutContext);
  const history = useHistory();
  const [leaveWork, setLeaveWork] = useState({
    ti: null,
    leave: false,
  });
  const [showCloseAllTabsDialog, setShowCloseAllTabsDialog] = useState(false);

  const {
    isNeedLeftArrow,
    isNeedRightArrow,
    handleScrollChangeEvent,
    moveLeft,
    moveRight,
  } = useHorizontalScroll({
    horizontalRef,
  });

  const { navigateToHome } = useNavigation();

  function handleClickTabItem(ti) {
    const routeToMove = ti?.id || ti?.route;
    if (activeTabItem !== routeToMove) {
      if (activeSubItem.has(routeToMove)) {
        const activeSub = activeSubItem.get(routeToMove);
        changeActiveTabItem(ti?.route);
        const routeToPush = activeSub?.id || routeToMove;
        history.push(routeToPush, { ...activeSub });
      } else {
        changeActiveTabItem(ti?.route);
        history.push(routeToMove, { ...ti });
      }
    }
  }

  function someSubTabHasChanged(routeToClose) {
    if (!isNil(activeSubItemObject) && !isEmpty(activeSubItemObject)) {
      const subItemsObjectsOfRoute = activeSubItemObject.filter(
        (x) =>
          x &&
          x.key &&
          isString(x.key) &&
          x.key.includes(routeToClose) &&
          !isNil(x.data) &&
          !isNil(x.data.formChanged) &&
          x.data.formChanged === true
      );

      return !isNil(subItemsObjectsOfRoute) && !isEmpty(subItemsObjectsOfRoute);
    }
    return false;
  }

  function handleCloseTabItem(ti) {
    let calculatedLeave = false;
    const routeToClose = ti?.route;

    //If tab is broken, or there is no active sub items status, or there is but is empty
    if (
      isNil(routeToClose) ||
      isNil(activeSubItemObject) ||
      (!isNil(activeSubItemObject) && isEmpty(activeSubItemObject))
    ) {
      calculatedLeave = false;
    } else {
      calculatedLeave = someSubTabHasChanged(routeToClose);
    }

    //final decision, show dialog or close tab directly
    if (calculatedLeave) {
      setLeaveWork({
        ti,
        leave: true,
      });
    } else {
      closeTabItem(ti);
    }
  }

  function handleLeaveWorkAction(leave) {
    if (leave) {
      closeTabItem(leaveWork.ti);
    }
    setLeaveWork({
      ti: null,
      leave: false,
    });
  }

  const getFormsToClose = useCallback(
    ({ tabItemClosing }) => {
      const subFormsToCloseKeys =
        filter(subItems, function (o) {
          return (
            !isNil(o?.parentId) &&
            !isNil(o?.entityName) &&
            o?.parentId === tabItemClosing?.route
          );
        })?.map((x) => `${x?.parentId}_${x?.entityName}`) || [];
      const subFormsToCloseIds =
        filter(
          activeSubItemObject,
          (o) => !isNil(o) && includes(subFormsToCloseKeys, o?.key)
        )?.map((x) => `${x?.formInstanceId}`) || [];

      const formItemsToClose = [
        ...filter(
          subFormsToCloseIds,
          (o) => !isNil(o) && isString(o) && !isEmpty(o)
        ),
      ];
      return formItemsToClose;
    },
    [activeSubItemObject, subItems]
  );

  function closeTabItem(ti) {
    const tabItemsArray = tabItems;
    removeTabItem(ti?.route);
    clearKeyFromRecordListData(ti?.route);
    removeAnalysisData(ti?.propText);
    removeReportData(ti);
    const formItemsToClose = getFormsToClose({ tabItemClosing: ti });
    notifyServerCleanFormInstanceId({
      formInstanceId: formItemsToClose,
      requestHeaders: REQUEST_HEADERS,
    });
    if (activeTabItem === ti?.route && tabItems.length === 1) {
      changeActiveTab(HOME_TAB);
      history.push(TAB_MAPPING[HOME_TAB]);
    } else if (activeTabItem === ti?.route && tabItems.length > 1) {
      const tabToMove = findNearestTabItemToMove({
        tabItemsArray,
        tabItemToRemove: ti,
      });
      if (isNil(tabToMove)) {
        changeActiveTab(HOME_TAB);
        clearActiveTabItem();
        navigateToHome();
      } else {
        const routeToMove = tabToMove?.route || tabToMove?.id;
        if (activeSubItem.has(routeToMove)) {
          const subToMove = activeSubItem.get(routeToMove);
          if (
            !isNil(subToMove) &&
            !isNil(subToMove.parentId) &&
            (!isNil(subToMove.id) || !isNil(subToMove.route))
          ) {
            const routeToPush = subToMove.id || subToMove.route;
            changeActiveTabItem(subToMove.parentId);
            history.push(routeToPush, { ...subToMove });
          }
        } else {
          changeActiveTabItem(routeToMove);
          history.push(routeToMove, { ...tabToMove });
        }
      }
    }
  }

  function handleCloseAllClick() {
    setShowCloseAllTabsDialog(true);
  }

  function handleCloseAllTabsAction(closeAll) {
    if (closeAll) {
      // clean forms globally
      if (!isNil(tabItems) && !isEmpty(tabItems)) {
        const formsItemGlobalToClose = [];
        for (const ti of tabItems) {
          if (!isNil(ti) && !isEmpty(ti)) {
            const formsToAddCalculated = getFormsToClose({
              tabItemClosing: ti,
            });
            if (
              !isNil(formsToAddCalculated) &&
              isArray(formsToAddCalculated) &&
              !isEmpty(formsToAddCalculated)
            ) {
              formsItemGlobalToClose.push(...formsToAddCalculated);
            }
          }
        }
        if (
          !isNil(formsItemGlobalToClose) &&
          !isEmpty(formsItemGlobalToClose)
        ) {
          notifyServerCleanFormInstanceId({
            formInstanceId: formsItemGlobalToClose,
            requestHeaders: REQUEST_HEADERS,
          });
        }
      }

      removeAllTabItems();
      navigateToHome();
    }
    setShowCloseAllTabsDialog(false);
  }

  function handleMakeFinalTab(e, ti, final) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    makeTabFinal({
      ...ti,
      final,
    });
  }

  function handleDoubleClickTabItem(e, ti, newFinal) {
    e.preventDefault();
    e.stopPropagation();
    handleMakeFinalTab(null, ti, newFinal);
  }

  async function handleFavBtnClick({ isFavItem, id }) {
    if (isFavItem) {
      await callServerToDeleteFavItem({ REQUEST_HEADERS, id });
    } else {
      await callServerToAddFavItem({ REQUEST_HEADERS, id });
    }
  }

  if (isNil(tabItems) || isEmpty(tabItems) || activeTab !== WORKSPACE_TAB) {
    return null;
  }

  return (
    <>
      <CloseTabDialog
        open={leaveWork.leave}
        handleAction={handleLeaveWorkAction}
      />

      <CloseAllTabsDialog
        open={showCloseAllTabsDialog}
        handleAction={handleCloseAllTabsAction}
      />

      <div className={classes.tabsContainer}>
        {tabItems.length > 1 && (
          <Tooltip title={t("MULTI_ITEM_CLOSE_ALL_TABS")}>
            <IconButton onClick={handleCloseAllClick} style={iconBtnStyles}>
              <Icon className="fas fa-times-circle" style={iconAuto} />
            </IconButton>
          </Tooltip>
        )}

        <IconButton
          onClick={moveLeft}
          disabled={!isNeedLeftArrow}
          style={iconBtnStyles}
        >
          <ChevronLeftIcon
            style={{
              cursor: "pointer",
            }}
          />
        </IconButton>

        <IconButton
          onClick={moveRight}
          disabled={!isNeedRightArrow}
          style={iconBtnStyles}
        >
          <ChevronRightIcon
            style={{
              cursor: "pointer",
            }}
          />
        </IconButton>

        <div
          className={classes.tabItemsContainer}
          ref={horizontalRef}
          onScroll={handleScrollChangeEvent}
        >
          {tabItems.map((ti) => {
            const iconClass = ti?.name || ti?.icon;
            const keyTab = ti?.route;
            const hasSubTabsChanged = someSubTabHasChanged(keyTab);
            const isFinalTab = ti?.final;

            const routeSt = toString(ti?.route);

            const isFormExeItem =
              !isNil(ti?.path) &&
              !isNil(ti?.idForm) &&
              !isEmpty(ti?.path) &&
              !isEmpty(ti?.idForm) &&
              endsWith(routeSt, `/${FORM_OPERATION_EXECUTION}`);

            const routeStExe = routeSt.substring(0, lastIndexOf(routeSt, "/"));

            const menuItemAssociated = find(flattenedMenuItems, {
              route: isFormExeItem ? routeStExe : routeSt,
            });

            const idOfItemInMenu =
              !isNil(menuItemAssociated) &&
              !isEmpty(menuItemAssociated) &&
              !isNil(menuItemAssociated?.id) &&
              isNumber(menuItemAssociated?.id)
                ? menuItemAssociated?.id
                : null;

            const isFavEnabled = !isNil(idOfItemInMenu);

            const isFavItem =
              !isNil(favItems) &&
              !isEmpty(favItems) &&
              some(favItems, { id: menuItemAssociated?.id });

            if (!isFinalTab && hasSubTabsChanged) {
              handleMakeFinalTab(null, ti, true);
            }

            return (
              <div
                key={keyTab}
                className={
                  activeTabItem === keyTab
                    ? classes.tabItemActive
                    : classes.tabItem
                }
              >
                <button
                  className={
                    !isFinalTab ? classes.tabButtonChanged : classes.tabButton
                  }
                  onClick={() => handleClickTabItem(ti)}
                  onDoubleClick={(e) =>
                    handleDoubleClickTabItem(e, ti, !isFinalTab)
                  }
                >
                  {isFinalTab ? (
                    <IconButton
                      disabled={hasSubTabsChanged}
                      className={
                        hasSubTabsChanged
                          ? classes.pinnedFinalDisabled
                          : classes.pinnedFinal
                      }
                      onClick={(e) => handleMakeFinalTab(e, ti, false)}
                    >
                      <Icon className="fas fa-thumbtack" style={iconAuto} />
                    </IconButton>
                  ) : (
                    <IconButton
                      className={classes.pinnedTemporary}
                      onClick={(e) => handleMakeFinalTab(e, ti, true)}
                    >
                      <Icon className="fas fa-thumbtack" style={iconAuto} />
                    </IconButton>
                  )}
                  <IconButton
                    className={
                      isFavItem ? classes.favButtonApplied : classes.favButton
                    }
                    disabled={!isFavEnabled || isUpdatingFavItems}
                    onClick={() =>
                      handleFavBtnClick({
                        isFavItem,
                        id: menuItemAssociated?.id,
                      })
                    }
                  >
                    <Icon className="fas fa-heart" style={iconAuto} />
                  </IconButton>
                  {!isNil(iconClass) && (
                    <Icon className={iconClass} style={iconAuto} />
                  )}
                  <Typography
                    style={{
                      fontWeight: activeTabItem === keyTab && "bold",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {!isNil(ti?.propText) && isObject(ti?.propText)
                      ? `${getLocaleText(ti?.propText, i18n)}`
                      : `${ti?.propText}`}
                  </Typography>
                  {hasSubTabsChanged && <SubHasChangedIcon />}
                </button>
                <button
                  onClick={() => handleCloseTabItem(ti)}
                  className={classes.closeButton}
                >
                  <Icon className="fas fa-times" style={closeIcon} />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
