import React, { useContext, useEffect, useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { InteractionStatus } from "@azure/msal-browser";
import useIsMounted from "react-is-mounted-hook";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { graphConfig } from "../../../../../core/sso/config/authConfig";
import { callMsGraph } from "../../../../../core/sso/api/MsGraphApiCall";
import { AuthContext } from "../../../../../core/providers/AuthContext";
import { CircularProgress, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "40px",
    width: "40px",
    marginLeft: "10px",
    borderRadius: "40px",
    backgroundColor: theme.palette.content.mainColor,
  },
  contentProfile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    backgroundColor: theme.palette.content.mainColor,
    [theme.breakpoints.up("md")]: {
      minHeight: 80,
      minWidth: 80,
      borderRadius: 80,
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 40,
      minWidth: 40,
      borderRadius: 40,
    },
  },
  contentNotes: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    backgroundColor: theme.palette.content.mainColor,
    [theme.breakpoints.up("md")]: {
      minHeight: 40,
      minWidth: 40,
      borderRadius: 40,
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 30,
      minWidth: 30,
      borderRadius: 30,
    },
  },
  userImg: {
    minHeight: 38,
    minWidth: 38,
    maxHeight: 38,
    maxWidth: 38,
    borderRadius: 40,
    border: `1px solid ${theme.palette.content.mainColor}`,
  },
  userImgProfile: {
    minHeight: 78,
    minWidth: 78,
    maxHeight: 78,
    maxWidth: 78,
    borderRadius: 100,
    border: `1px solid ${theme.palette.content.mainColor}`,
  },
  userImgNotes: {
    minHeight: 38,
    minWidth: 38,
    maxHeight: 38,
    maxWidth: 38,
    borderRadius: 100,
    border: `1px solid ${theme.palette.content.mainColor}`,
  },
}));

export default function UserImage({ isProfile, isNotes }) {
  const classes = useStyles();
  const [photo, setPhoto] = useState(null);
  const [loadingPhoto, setLoadingPhoto] = useState(false);

  const { auth } = useContext(AuthContext);
  const { accounts, instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const isMounted = useIsMounted();

  const upperName = useMemo(
    () => auth?.userName.substring(0, 2).toUpperCase(),
    [auth.userName]
  );

  useEffect(() => {
    let blobToRevoke = null;
    if (
      inProgress === InteractionStatus.None &&
      isAuthenticated === true &&
      accounts &&
      accounts.length > 0
    ) {
      if (isMounted()) {
        setLoadingPhoto(true);
        callMsGraph({ endpoint: graphConfig.graphMePhoto })
          .then((response) => response && response.blob())
          .then((blob) => {
            if (isMounted() && blob) {
              blobToRevoke = blob;
              setPhoto({ src: URL.createObjectURL(blob) });
            }
            setLoadingPhoto(false);
          })
          .catch(() => {
            setLoadingPhoto(false);
            setPhoto(null);
          });
      }
    } else {
      setLoadingPhoto(false);
      setPhoto(null);
    }

    if (blobToRevoke !== null) {
      return () => window.URL.revokeObjectURL(blobToRevoke);
    } else {
      return () => null;
    }
    //eslint-disable-next-line
  }, [inProgress, instance, isAuthenticated, accounts, upperName]);

  return (
    <div
      className={
        isProfile
          ? classes.contentProfile
          : isNotes
          ? classes.contentNotes
          : classes.content
      }
    >
      {loadingPhoto ? (
        <CircularProgress style={{ color: "#FFF" }} size={18} />
      ) : photo && photo.src ? (
        <img
          className={
            isProfile
              ? classes.userImgProfile
              : isNotes
              ? classes.userImgNotes
              : classes.userImg
          }
          src={photo.src}
          alt="user_photo"
          onError={() => {
            setLoadingPhoto(false);
            setPhoto(null);
          }}
        />
      ) : (
        <Typography style={{ color: "white", fontSize: "18px" }}>
          {upperName}
        </Typography>
      )}
    </div>
  );
}
